import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import FlowCanvas from './components/FlowCanvas';
import QuillEditor from './components/QuillEditor';
import Login from './components/Login';
import ReadOnlyProject from './components/ReadOnlyProject';
import Onboarding from './components/Onboarding';
import { supabase } from './supabaseClient';
import { ToastContainer, toast } from 'react-toastify';
import { Oval } from 'react-loader-spinner';
import { auth } from './firebase';  // Import the initialized auth instance
import { onAuthStateChanged } from "firebase/auth";
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const MainApp = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showEditor, setShowEditor] = useState(false);
  const [editorWidth, setEditorWidth] = useState(45); // Initial width as percentage
  const editorRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        // Fetch or insert user data into Supabase here if necessary
        const { data: userData, error } = await supabase
          .from('users')
          .select('*')
          .eq('firebase_uid', firebaseUser.uid)  // Use firebase_uid instead of id
          .single();
          
        if (error) {
          console.error("Error fetching user data from Supabase:", error);
        } else {
          setUser({
            ...firebaseUser,
            ...userData,
          });
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('success')) {
      toast.success('Payment successful! Credits have been added to your account.');
    }
  }, [location]);

  if (loading) {
    return (
      <div className="loading">
        <Oval color="#00BFFF" height={80} width={80} />
        <p>Loading...</p>
      </div>
    );
  }

  if (!user) {
    return <Login />;
  }

  const handleViewResult = (nodeId) => {
    setShowEditor(true);
    if (editorRef.current) {
      editorRef.current.setActiveTab(nodeId);
    }
  };

  const handleMinimizeEditor = () => {
    setShowEditor(false);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const newWidth = ((window.innerWidth - e.clientX) / window.innerWidth) * 100;
    if (newWidth >= 10 && newWidth <= 90) {
      setEditorWidth(newWidth);
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className="App">
      <FlowCanvas user={user} onViewResult={handleViewResult} editorRef={editorRef} />
      {showEditor && <div className="divider" onMouseDown={handleMouseDown} style={{ right: `${editorWidth}%` }}></div>}
      <div className={`editor-pane ${showEditor ? 'show' : ''}`} style={{ width: `${editorWidth}%` }}>
        <QuillEditor ref={editorRef} onMinimize={handleMinimizeEditor} activeTab={null} />
      </div>
      <ToastContainer />
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/read-only/:shareableLink" element={<ReadOnlyProject />} />
        <Route path="/login" element={<Login />} /> {/* Explicit Login route */}
        <Route path="/onboarding" element={<Onboarding />} /> {/* Onboarding route */}
        <Route path="/" element={<MainApp />} />
      </Routes>
    </Router>
  );
};

export default App;
