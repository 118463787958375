import React, { useState, useEffect } from 'react';
import { Handle } from 'react-flow-renderer';
import { Box, IconButton, Paper, CircularProgress, Typography, TextField } from '@mui/material';
import { Delete as DeleteIcon, Visibility as VisibilityIcon, Add as AddIcon, Check as CheckIcon, Edit as EditIcon } from '@mui/icons-material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './GeneratedNode.css';

const GeneratedNode = ({ id, data }) => {
  const [loading, setLoading] = useState(data.loading);
  const [editableHtml, setEditableHtml] = useState(data.populatedHtml);
  const [editableLabel, setEditableLabel] = useState(data.label);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (data.loading !== undefined) {
      setLoading(data.loading);
    }

    if (!data.loading && data.result) {
      toast.success(`Content for node ${id} is ready!`);
    }
  }, [data.loading, data.result, id]);

  const handleAddNodeClick = () => {
    console.log(`GeneratedNode: Adding node from ${id}`);
    data.toggleDropdown(id);
  };

  const handleViewResultClick = () => {
    console.log(`GeneratedNode: Viewing result for node ${id}`);
    data.onViewResult(id);
  };

  const handleDeleteClick = () => {
    console.log(`GeneratedNode: Deleting node ${id}`);
    data.onDelete(id);
  };

  const handleRunClick = async () => {
    const connectedNodeResults = data.getConnectedNodeResults(id);
    const combinedInput = connectedNodeResults.join(' ');
    console.log(`GeneratedNode: Executing node ${id} with combined input: ${combinedInput}`);
    await data.onExecute(combinedInput);
  };

  const handleHtmlChange = (event) => {
    setEditableHtml(event.target.value);
  };

  const handleLabelChange = (event) => {
    setEditableLabel(event.target.value);
  };

  const saveChanges = () => {
    data.onHtmlChange(id, editableHtml);
    data.onLabelChange(id, editableLabel);
    setIsEditing(false);
  };

  const handleBlur = () => {
    saveChanges();
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    saveChanges();
  };

  return (
    <Paper className="generated-node" elevation={3}>
      <Handle
        type="target"
        position="left"
        style={{ background: '#6366f1', width: '12px', height: '12px', borderRadius: '50%' }}
      />
      <Box className="generated-node-header" style={{ backgroundColor: data.color }}>
        {isEditing ? (
          <TextField
            value={editableLabel}
            onChange={handleLabelChange}
            onBlur={handleBlur}
            fullWidth
            variant="outlined"
            size="small"
          />
        ) : (
          <>
            <i className={`${data.icon || 'fas fa-lightbulb'} node-header-icon`} />
            {editableLabel}
          </>
        )}
      </Box>
      <Box className="generated-node-content">
        {isEditing ? (
          <TextField
            multiline
            variant="outlined"
            value={editableHtml}
            onChange={handleHtmlChange}
            onBlur={handleBlur}
            fullWidth
          />
        ) : (
          <Typography variant="body2" className="node-description" dangerouslySetInnerHTML={{ __html: editableHtml }} />
        )}
        {loading && (
          <Box className="node-loading">
            <CircularProgress color="primary" size={30} />
          </Box>
        )}
      </Box>
      <Box className="button-container">
        <Box className="left-buttons">
          <IconButton onClick={handleViewResultClick} className="icon-button gradient-icon">
            <VisibilityIcon />
          </IconButton>
          <IconButton onClick={handleDeleteClick} className="icon-button gradient-icon">
            <DeleteIcon />
          </IconButton>
          {isEditing ? (
            <IconButton onClick={handleSaveClick} className="icon-button gradient-icon">
              <CheckIcon />
            </IconButton>
          ) : (
            <IconButton onClick={handleEditClick} className="icon-button gradient-icon">
              <EditIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      <Handle
        type="source"
        position="right"
        style={{ background: '#6366f1', width: '12px', height: '12px', borderRadius: '50%' }}
      />
      {!loading && (
        <IconButton
          className="node-add-button"
          onClick={handleAddNodeClick}
          color="primary"
          style={{
            position: 'absolute',
            top: '-15px',
            right: '-15px',
            background: '#6366f1',
            color: 'white',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          <AddIcon />
        </IconButton>
      )}
    </Paper>
  );
};

export default GeneratedNode;
