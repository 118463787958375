export const modulesConfig = {
  userInput: [
    {
      id: 'user-input-node',
      data: {
        label: 'Define Your Objective',
        icon: 'fas fa-keyboard',
        description: 'Specify what you want to explore. Start by dragging a module onto the canvas to establish your initial focus.',
        category: 'input',
        subcategory: 'general',
        color: '#52c9ff' // Blue
      }
    }
  ],
  scenarios: {

    leanStartupMethodology: {
      id: 'leanStartupMethodology',
      label: 'Start a New Business',
      description: 'Break down your business idea into key components like customer segments, value propositions, and revenue streams to visualize how your business will operate.',

      icon: 'fas fa-sync',
      color: '#DFFFE8', // Lighter Light green

      modules: [
        {
          id: 'Business Model Canvas',
          data: {
            label: 'Business Model Canvas',
            icon: 'fas fa-chart-pie',
            description: 'Identify and organize essential parts of your business, such as customers, value propositions, and financials.',
            category: 'standaloneModules',
            subcategory: 'Research',
            color: '#FFE8D1' // Light Peach
          }
        },
        {
          id: 'leanCanvas',
          data: {
            label: 'Understand Core Business Elements',
            icon: 'fas fa-chart-pie',
            description: 'Highlight and structure the main elements of your business idea for clarity and communication.',
            category: 'standaloneModules',
            subcategory: 'Product',
            color: '#EAD7FF' // Light Purple
          }
        },
        {
          id: 'productMarketFit',
          data: {
            label: 'Check Product-Market Fit',
            icon: 'fas fa-check',
            description: 'Evaluate if your product meets the needs of your target market to ensure it’s a good fit.',
            category: 'standaloneModules',
            subcategory: 'Product',
            color: '#EAD7FF' // Light Purple
          }
        },
        {
          id: 'Customer Journey Map',
          data: {
            label: 'Customer Journey Map',
            icon: 'fas fa-map-marked-alt',
            description: 'Visualize the steps a customer takes from first contact to purchase, helping you improve their experience.',
            category: 'standaloneModules',
            subcategory: 'Research',
            color: '#FFE8D1' // Light Peach
          }
        },
        {
          id: 'goToMarketStrategy',
          data: {
            label: 'Plan Your Product Launch',
            icon: 'fas fa-rocket',
            description: 'Organize and prepare the steps necessary for successfully launching your product in the market.',
            category: 'standaloneModules',
            subcategory: 'Marketing',
            color: '#FFD9D3' // Soft Pink
          }
        }
      ]
    },

    trendMapping: {
      id: 'trendMapping',
      label: 'Track Market Trends',
      icon: 'fas fa-binoculars',
      description: 'Identify and analyze market trends to stay ahead and adapt to changes.',

      color: '#E0FFF7', // Lighter Light cyan

      modules: [
        {
          id: 'TrendDiscovery',
          data: {
            label: 'Find New Trends',
            icon: 'fas fa-chart-line',
            description: 'Explore emerging trends to stay updated with the latest developments in your industry.',
            category: 'standaloneModules',
            subcategory: 'Research',
            color: '#FFE8D1' // Light Peach
          }
        },
        {
          id: 'EcosystemMapping',
          data: {
            label: 'Understand Market Landscape',
            icon: 'fas fa-chart-line',
            description: 'Map out key players and relationships within your industry to understand the market ecosystem.',
            category: 'standaloneModules',
            subcategory: 'Research',
            color: '#FFE8D1' // Light Peach
          }
        },
        {
          id: 'CrossIndustryScouting',
          data: {
            label: 'Get Ideas from Other Industries',
            icon: 'fas fa-binoculars',
            description: 'Look beyond your industry for innovative ideas that can be applied to your own projects.',
            category: 'standaloneModules',
            subcategory: 'Research',
            color: '#FFE8D1' // Light Peach
          }
        },
        {
          id: 'WhatIf',
          data: {
            label: 'Consider Hypothetical Scenarios',
            icon: 'fas fa-question',
            description: 'Explore "what if" scenarios to anticipate potential changes and plan accordingly.',
            category: 'standaloneModules',
            subcategory: 'Ideation',
            color: '#E1F5FF' // Light Blue
          }
        }
      ]
    },

    userExperienceResearch: {
      id: 'userExperienceResearch',
      label: 'Understand Customer Insights',
      description: 'Map the customer experience to better understand their needs, behaviors, and pain points.',

      icon: 'fas fa-poll',
      color: '#FFE8D1', // Lighter Light orange

      modules: [
        {
          id: 'User Persona',
          data: {
            label: 'Create Customer Profiles',
            icon: 'fas fa-user-tag',
            description: 'Build detailed profiles of your target customers to better understand their needs and behaviors.',
            category: 'standaloneModules',
            subcategory: 'Research',
            color: '#FFF8E0' // Light Yellow
          }
        },
        {
          id: 'Customer Journey Map',
          data: {
            label: 'Track Customer Interactions',
            icon: 'fas fa-map-marked-alt',
            description: 'Visualize the steps a customer takes from first contact to purchase, helping you improve their experience.',
            category: 'standaloneModules',
            subcategory: 'Research',
            color: '#FFF8E0' // Light Yellow
          }
        },
        {
          id: 'AIDAModel',
          data: {
            label: 'Engage and Convert Customers',
            icon: 'fas fa-heart',
            description: 'Use strategies to capture attention, build interest, and drive customer action.',
            category: 'standaloneModules',
            subcategory: 'Marketing',
            color: '#FFD9D3' // Soft Pink
          }
        }
      ]
    }
  },

  standaloneModules: [
    {
      id: 'sm1',
      data: {
        label: 'Come Up With New Ideas',
        icon: 'fas fa-lightbulb',
        description: 'Encourage creative thinking to come up with new ideas for your projects.',
        category: 'standaloneModules',
        subcategory: 'Ideation',
        color: '#E1F5FF' // Light Blue
      }
    },
    {
      id: 'WhatIf',
      data: {
        label: 'Consider Hypothetical Scenarios',
        icon: 'fas fa-question',
        description: 'Explore "what if" scenarios to anticipate potential changes and plan accordingly.',
        category: 'standaloneModules',
        subcategory: 'Ideation',
        color: '#E1F5FF' // Light Blue
      }
    },
    {
      id: 'Business Model Canvas',
      data: {
        label: 'Business Model Canvas',
        icon: 'fas fa-chart-pie',
        description: 'Identify and organize essential parts of your business, such as customers, value propositions, and financials.',
        category: 'standaloneModules',
        subcategory: 'Research',
        color: '#FFE8D1' // Light Peach
      }
    },
    {
      id: 'Myths',
      data: {
        label: 'Debunk Common Myths',
        icon: 'fas fa-exclamation-circle',
        description: 'Identify and dispel common myths within your industry, helping you separate fact from fiction and make informed decisions.',
        category: 'standaloneModules',
        subcategory: 'Research',
        color: '#FFE8D1' // Light Peach
      }
    },
    {
      id: 'marketresearch',
      data: {
        label: 'Do Market Research',
        icon: 'fas fa-search',
        description: 'Analyse and interpret data about your target market to understand the market size, competition, and customer needs.',
        category: 'standaloneModules',
        subcategory: 'Research',
        color: '#FFE8D1' // Light Peach
      }
    },

    {
      id: 'elevatorpitch',
      data: {
        label: 'Craft Your Elevator Pitch',
        icon: 'fas fa-bullhorn',
        description: 'Develop a concise and compelling elevator pitch that clearly communicates your business idea, value proposition, and goals in under a minute.',
        category: 'standaloneModules',
        subcategory: 'Marketing',
        color: '#FFE8D1' // Light Peach
      }
    },
    
    {
      id: 'customerdiscovery',
      data: {
        label: 'Discover Where Your Customers Are',
        icon: 'fas fa-map-marker-alt',
        description: 'Identify and explore the best channels, platforms, and locations where your target customers are most active and accessible.',
        category: 'standaloneModules',
        subcategory: 'Research',
        color: '#FFE8D1' // Light Peach
      }
    },
    
    {
      id: 'Customer Journey Map',
      data: {
        label: 'Customer Journey Map',
        icon: 'fas fa-map-marked-alt',
        description: 'Visualize the steps a customer takes from first contact to purchase, helping you improve their experience.',
        category: 'standaloneModules',
        subcategory: 'Research',
        color: '#FFE8D1' // Light Peach
      }
    },
    {
      id: 'CrossIndustryScouting',
      data: {
        label: 'Get Ideas from Other Industries',
        icon: 'fas fa-binoculars',
        description: 'Look beyond your industry for innovative ideas that can be applied to your own projects.',
        category: 'standaloneModules',
        subcategory: 'Research',
        color: '#FFE8D1' // Light Peach
      }
    },
    {
      id: 'EcosystemMapping',
      data: {
        label: 'Understand Market Landscape',
        icon: 'fas fa-chart-line',
        description: 'Map out key players and relationships within your industry to understand the market ecosystem.',
        category: 'standaloneModules',
        subcategory: 'Research',
        color: '#FFE8D1' // Light Peach
      }
    },
    {
      id: 'TrendDiscovery',
      data: {
        label: 'Understand New Trends',
        icon: 'fas fa-chart-line',
        description: 'Explore emerging trends to stay updated with the latest developments in your industry.',
        category: 'standaloneModules',
        subcategory: 'Research',
        color: '#FFE8D1' // Light Peach
      }
    },
    {
      id: 'HMW',
      data: {
        label: 'Redefine Problems as Opportunities',
        icon: 'fas fa-exchange-alt',
        description: 'Identify challenges and convert them into opportunities for growth and improvement.',
        category: 'standaloneModules',
        subcategory: 'Ideation',
        color: '#E1F5FF' // Light Blue
      }
    },
    {
      id: 'Scamper',
      data: {
        label: 'Scamper Technique',
        icon: 'fas fa-wrench',
        description: 'Adapt and modify existing ideas to enhance them and generate better solutions.',
        category: 'standaloneModules',
        subcategory: 'Ideation',
        color: '#E1F5FF' // Light Blue
      }
    },
    {
      id: 'User Persona',
      data: {
        label: 'Create Customer Profile',
        icon: 'fas fa-user-tag',
        description: 'Build detailed profiles of your target customers to better understand their needs and behaviors.',
        category: 'standaloneModules',
        subcategory: 'Research',
        color: '#FFE8D1' // Light Peach
      }
    },
    {
      id: 'AIDAModel',
      data: {
        label: 'Understand How To Convert Customers',
        icon: 'fas fa-heart',
        description: 'Use strategies to capture attention, build interest, and drive customer action.',
        category: 'standaloneModules',
        subcategory: 'Marketing',
        color: '#FFD9D3' // Soft Pink
      }
    },
    {
      id: 'ContentMarketingMatrix',
      data: {
        label: 'Plan Your Content Strategy',
        icon: 'fas fa-th-large',
        description: 'Organize your content creation efforts to ensure a diverse and consistent output.',
        category: 'standaloneModules',
        subcategory: 'Marketing',
        color: '#FFD9D3' // Soft Pink
      }
    },
    {
      id: 'PainGainMap',
      data: {
        label: 'Identify Pains and Solutions',
        icon: 'fas fa-exchange-alt',
        description: 'Pinpoint customer pain points and brainstorm potential solutions to address them effectively.',
        category: 'standaloneModules',
        subcategory: 'Product',
        color: '#EAD7FF' // Light Purple
      }
    },
    {
      id: 'leanCanvas',
      data: {
        label: 'Understand Core Business Elements',
        icon: 'fas fa-chart-pie',
        description: 'Highlight and structure the main elements of your business idea for clarity and communication.',
        category: 'standaloneModules',
        subcategory: 'Product',
        color: '#EAD7FF' // Light Purple
      }
    },
    {
      id: 'goToMarketStrategy',
      data: {
        label: 'Plan Your Go To Market Strategy',
        icon: 'fas fa-rocket',
        description: 'Organize and prepare the steps necessary for successfully launching your product in the market.',
        category: 'standaloneModules',
        subcategory: 'Marketing',
        color: '#FFD9D3' // Soft Pink
      }
    },
    {
      id: 'productMarketFit',
      data: {
        label: 'Check Product-Market Fit',
        icon: 'fas fa-check',
        description: 'Evaluate if your product meets the needs of your target market to ensure it’s a good fit.',
        category: 'standaloneModules',
        subcategory: 'Product',
        color: '#EAD7FF' // Light Purple
      }
    },
    {
      id: 'brandStrategyCanvas',
      data: {
        label: 'Develop Your Brand Strategy',
        icon: 'fas fa-flag',
        description: 'Define your brand’s identity and how it communicates its message to build a strong and consistent presence.',
        category: 'standaloneModules',
        subcategory: 'Marketing',
        color: '#FFD9D3' // Soft Pink
      }
    }
  ],

  actions: [
    {
      id: 'ac4',
      data: {
        label: 'Gather Feedback',
        icon: 'fas fa-comment-dots',
        description: 'Collect and analyze customer feedback to make improvements and enhance your project’s success.',
        category: 'actions',
        subcategory: 'general',
        color: '#FFDDD0' // Lighter Light coral
      }
    },
  
    {
      id: 'ac6',
      data: {
        label: 'Draft a One-Page Business Plan',
        icon: 'fas fa-exclamation-triangle',
        description: 'Quickly summarize your project’s key elements in a concise one-page business plan.',
        category: 'actions',
        subcategory: 'general',
        color: '#FFDDD0' // Lighter Light coral
      }
    }
  ]
};
