import React, { useCallback, useRef, useEffect, useState } from 'react';
import ReactFlow, { useNodesState, useEdgesState, MiniMap, Controls, Background } from 'react-flow-renderer';
import { useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import DeleteConnectionModal from './DeleteConnectionModal';
import CustomNode from './CustomNode';
import UserInputNode from './UserInputNode';
import GeneratedNode from './GeneratedNode';
import { setEdges, addEdge, removeEdge } from '../slices/edgesSlice';
import useStore from '../zustandStore';
import 'react-flow-renderer/dist/style.css';
import Toolbar from './Toolbar';
import { updateResult, replaceResult } from '../slices/resultsSlice';
import { setNodes, updateNodeData, addNode, removeNode } from '../slices/nodesSlice';
import { setUserInput } from '../slices/userInputSlice';
import { executePrompt } from '../api';
import { executeScenario } from '../thunks/thunk';
import { stripHtmlTags } from '../utils';

const nodeTypes = {
  customNode: CustomNode,
  userInputNode: UserInputNode,
  generatedNode: GeneratedNode,
};

const NODE_WIDTH = 400;
const GENERATED_NODE_WIDTH = 600;
const NODE_GAP = 200;
const VERTICAL_GAP = 400;

const generateNodeId = (type, index) => {
  return `${type}-${index}-${Date.now()}`;
};

const safeParseJSON = (value, fallback) => {
  try {
    return JSON.parse(value) || fallback;
  } catch (e) {
    return fallback;
  }
};

export const createGeneratedNodes = (
  dispatch, 
  triggerNodeId, 
  json, 
  toggleDropdown, 
  executeScenario, 
  onDelete, 
  nodes, 
  edges, 
  handleViewResult, 
  readOnly, 
  onHtmlChange, 
  onLabelChange
) => {
  console.log('Creating generated nodes for:', triggerNodeId, json);
  
  const triggerNode = nodes.find(node => node.id === triggerNodeId);
  if (!triggerNode) return;

  const getRightHandlePosition = (node) => {
    const nodeWidth = node.type === 'generatedNode' ? GENERATED_NODE_WIDTH : NODE_WIDTH;
    return {
      x: node.position.x + nodeWidth,
      y: node.position.y
    };
  };

  const triggerHandlePos = getRightHandlePosition(triggerNode);

  // Find the last generated node to calculate the start position for new nodes
  const existingGeneratedNodes = nodes.filter(node => node.id.startsWith(`${triggerNodeId}-generated`));
  const lastGeneratedNode = existingGeneratedNodes.sort((a, b) => b.position.y - a.position.y)[0];
  
  // Calculate the starting Y-position for new nodes
  const startYPosition = lastGeneratedNode 
    ? lastGeneratedNode.position.y + VERTICAL_GAP 
    : triggerHandlePos.y + 120 - 400; // default position if no generated nodes exist
  
  const ideas = Object.keys(json)
    .filter(key => key.startsWith('idea') && key.endsWith('title'))
    .map((key, index) => {
      const ideaIndex = key.match(/\d+/)[0];
      const nodeId = `${triggerNodeId}-generated-${index}-${Date.now()}`; // Ensures uniqueness
      const result = stripHtmlTags(json[`idea${ideaIndex}_description`]);
      const populatedHtml = result;

      dispatch(updateResult({ id: nodeId, result, populatedHtml }));

      return {
        id: nodeId,
        type: 'generatedNode',
        position: {
          x: triggerHandlePos.x + NODE_GAP,
          y: startYPosition + index * VERTICAL_GAP
        },
        data: {
          label: json[key],
          populatedHtml,
          category: 'generated',
          id: nodeId,
          handleCount: 1,
          toggleDropdown,
          onViewResult: () => handleViewResult(nodeId),
          parentId: triggerNodeId,
          onDelete: () => onDelete(nodeId),
          onExecute: (nodeContent) => dispatch(executeScenario({
            input: nodeContent || result,
            startNodeId: nodeId,
            toggleDropdown
          })),
          getConnectedNodeResults: (nodeId) => getConnectedNodeResults(nodeId),
          edges,
          nodes,
          onHtmlChange,
          onLabelChange,
        },
      };
    });

  console.log('Generated nodes:', ideas);
  if (ideas.length > 0) {
    dispatch(addNode(ideas));
    console.log('Dispatched addNode with:', ideas);

    const newEdges = ideas.map((newNode) => ({
      id: `e${triggerNodeId}-${newNode.id}`,
      source: triggerNodeId,
      target: newNode.id,
      type: 'default',
      style: { strokeWidth: 5 },
    }));

    dispatch(addEdge(newEdges));
    console.log('Dispatched addEdge with:', newEdges);
  } else {
    console.log('No ideas generated from JSON');
  }
};



const FlowCanvas = ({ user, initialNodes, initialEdges, initialResults, initialUserInput, onViewResult, editorRef, readOnly }) => {
  const dispatch = useDispatch();
  const reduxNodes = useSelector((state) => state.nodes || []);
  const reduxEdges = useSelector((state) => state.edges || []);
  const results = useSelector((state) => state.results);
  const userInput = useSelector((state) => state.userInput);
  const { setNodes: setZustandNodes, setEdges: setZustandEdges } = useStore();
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownNodeId, setDropdownNodeId] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [activeTab, setActiveTab] = useState(reduxNodes.length > 0 ? reduxNodes[0].id : null);
  const [initialized, setInitialized] = useState(false);
  const [nodes, setNodesState, onNodesChange] = useNodesState(initialNodes || reduxNodes);
  const [edges, setEdgesState, onEdgesChange] = useEdgesState(initialEdges || reduxEdges);
  const reactFlowInstance = useRef(null); // NEW: Ref to hold the ReactFlow instance

  // NEW: Callback to initialize ReactFlow and call fitView for read-only mode
  const onInit = useCallback((instance) => {
    reactFlowInstance.current = instance;
    if (readOnly) {
      instance.fitView({ padding: 0.2, duration: 800 }); // Auto-fit view when read-only
    }
  }, [readOnly]);

  // NEW: Auto-fit the view when nodes or edges update in read-only mode
  useEffect(() => {
    if (readOnly && reactFlowInstance.current) {
      reactFlowInstance.current.fitView({ padding: 0.2 }); // Adjust padding as needed
    }
  }, [readOnly, nodes, edges]); // Dependencies: run when nodes or edges change in read-only

  const onHtmlChange = useCallback((id, newHtml) => {
    setNodesState((prevNodes) =>
      prevNodes.map((node) =>
        node.id === id ? { ...node, data: { ...node.data, populatedHtml: newHtml } } : node
      )
    );
  }, [setNodesState]);

  const onLabelChange = useCallback((id, newLabel) => {
    setNodesState((prevNodes) =>
      prevNodes.map((node) =>
        node.id === id ? { ...node, data: { ...node.data, label: newLabel } } : node
      )
    );
  }, [setNodesState]);

  const toggleDropdown = (nodeId = null) => {
    console.log('toggleDropdown called with nodeId:', nodeId); // Debugging information
    setDropdownNodeId(nodeId);
    setShowDropdown((prevShow) => !prevShow);
  };

  const getConnectedNodeResults = useCallback((nodeId) => {
    const connectedEdges = edges.filter(edge => edge.target === nodeId);
    const results = connectedEdges.map(edge => {
        const sourceNode = nodes.find(node => node.id === edge.source);
        if (sourceNode.type === 'userInputNode') {
            return stripHtmlTags(userInput[sourceNode.id] || '');
        } else if (sourceNode.data && sourceNode.data.populatedHtml) {
            return stripHtmlTags(sourceNode.data.populatedHtml);
        } else if (sourceNode.data && sourceNode.data.result) {
            return stripHtmlTags(sourceNode.data.result);
        } else {
            return '';
        }
    });
    console.log(`getConnectedNodeResults: Combined input for node ${nodeId} - Results: ${results.join(' ')}`);
    return results;
}, [edges, nodes, userInput]);

  const updateLastNodeFlag = (nodes) => {
    const updatedNodes = nodes.map((node, index) => ({
      ...node,
      data: {
        ...node.data,
        isLastNode: node.type !== 'generatedNode' && node.id !== 'sm1' && index === nodes.length - 1,
        toggleDropdown,
        onViewResult,
        onDelete: () => onDelete(node.id),
        onExecute: (input) => handleExecuteScenario(input || node.data.description, node.id),
        getConnectedNodeResults: (nodeId) => getConnectedNodeResults(nodeId),
      },
    }));

    return updatedNodes;
  };

  const handleExecuteScenario = useCallback(async (input, startNodeId) => {
    if (readOnly) return;
    const node = nodes.find(node => node.id === startNodeId);
    if (!node) return;

    try {
        dispatch(updateNodeData({ id: node.id, data: { loading: true } }));

        const nodeData = {
            category: node.data.category,
            moduleId: node.data.moduleId || node.data.id,
        };

        if (node.type === 'userInputNode') {
            const currentInput = userInput[node.id];
            dispatch(updateNodeData({ id: node.id, data: { loading: false, result: stripHtmlTags(currentInput) } }));
            return;
        }

        const connectedNodeResults = getConnectedNodeResults(node.id);
        const combinedInput = connectedNodeResults.join(' ') || input;
        const userId = user?.id;

        console.log(`handleExecuteScenario: Executing node ${node.id} with combined input: ${combinedInput}`);

        const response = await executePrompt([nodeData], combinedInput, userId);

        const result = response[0];

        console.log('API result for node', node.id, ':', result);

        dispatch(replaceResult({ id: node.id, result: stripHtmlTags(result.detailedText), populatedHtml: result.populatedHtml }));
        dispatch(updateNodeData({ id: node.id, data: { loading: false, result: stripHtmlTags(result.detailedText) } }));

        if (result.jsonOutput && nodeData.moduleId === 'sm1') {
            console.log('Received JSON for sm1:', result.jsonOutput);
            createGeneratedNodes(dispatch, node.id, result.jsonOutput, toggleDropdown, executeScenario, (id) => dispatch(removeNode(id)), nodes, edges, onViewResult, readOnly, onHtmlChange, onLabelChange);
        }
    } catch (error) {
        console.error('Error executing scenario for node', node.id, ':', error);
        dispatch(updateNodeData({ id: node.id, data: { loading: false, error: error.message } }));
    }
}, [dispatch, nodes, edges, readOnly, user, userInput, getConnectedNodeResults, onViewResult, toggleDropdown, executeScenario, onHtmlChange, onLabelChange]);

useEffect(() => {
  if (initialUserInput && Object.keys(initialUserInput).length > 0) {
    Object.entries(initialUserInput).forEach(([id, value]) => {
      dispatch(setUserInput({ id, value: stripHtmlTags(value) }));
    });
  }
}, [initialUserInput, dispatch]);

useEffect(() => {
  if (!initialized) {
    if (initialNodes && initialEdges && initialResults && initialUserInput) {
      setNodesState(initialNodes);
      setEdgesState(initialEdges);
      dispatch(setNodes(initialNodes));
      dispatch(setEdges(initialEdges));
      initialResults.forEach((result) => {
        dispatch(replaceResult({ id: result.id, result: stripHtmlTags(result.result), populatedHtml: result.populatedHtml }));
      });

      if (initialUserInput && Object.keys(initialUserInput).length > 0) {
        Object.entries(initialUserInput).forEach(([id, value]) => {
          dispatch(setUserInput({ id, value: stripHtmlTags(value) }));
        });
      }
      
      setInitialized(true);
    } else {
      const storedNodes = localStorage.getItem('flow-nodes');
      const storedEdges = localStorage.getItem('flow-edges');
      const storedResults = localStorage.getItem('flow-results');
      const storedUserInput = localStorage.getItem('flow-user-input');

      const parsedNodes = safeParseJSON(storedNodes, []);
      const parsedEdges = safeParseJSON(storedEdges, []);
      const parsedResults = safeParseJSON(storedResults, {});
      const parsedUserInput = safeParseJSON(storedUserInput, {});

      if (parsedNodes.length === 0) {
        const initialUserInputNode = {
          id: 'user-input-node',
          type: 'userInputNode',
          position: { x: 50, y: 50 },
          data: { label: 'User Input', category: 'input', id: 'user-input-node', toggleDropdown, onViewResult, isLastNode: true },
        };

        const initialNodes = [initialUserInputNode];

        setNodesState(initialNodes);
        setZustandNodes(initialNodes);
        dispatch(setNodes(initialNodes));
      } else {
        setNodesState(parsedNodes);
        setZustandNodes(parsedNodes);
        dispatch(setNodes(parsedNodes));
      }

      setEdgesState(parsedEdges);
      setZustandEdges(parsedEdges);
      dispatch(setEdges(parsedEdges));

      Object.entries(parsedResults).forEach(([id, result]) => {
        dispatch(replaceResult({ id, result: stripHtmlTags(result.result), populatedHtml: result.populatedHtml }));
      });

      Object.entries(parsedUserInput).forEach(([id, value]) => {
        dispatch(setUserInput({ id, value: stripHtmlTags(value) }));
      });

      setInitialized(true);
    }
  }
}, [initialized, initialNodes, initialEdges, initialResults, initialUserInput, setNodesState, setZustandNodes, setEdgesState, setZustandEdges, dispatch]);

useEffect(() => {
  if (initialized) {
    const updatedNodes = updateLastNodeFlag(reduxNodes);
    setNodesState(updatedNodes);
    setZustandNodes(updatedNodes);
    if (!readOnly) {
      localStorage.setItem('flow-nodes', JSON.stringify(updatedNodes));
    }
  }
}, [reduxNodes, setNodesState, setZustandNodes, readOnly, initialized]);

useEffect(() => {
  if (initialized) {
    setEdgesState(reduxEdges);
    setZustandEdges(reduxEdges);
    if (!readOnly) {
      localStorage.setItem('flow-edges', JSON.stringify(reduxEdges));
    }
  }
}, [reduxEdges, setEdgesState, setZustandEdges, readOnly, initialized]);

useEffect(() => {
  if (initialized) {
    const storedResults = results.reduce((acc, result) => {
      acc[result.id] = { result: stripHtmlTags(result.result), populatedHtml: result.populatedHtml };
      return acc;
    }, {});
    if (!readOnly) {
      localStorage.setItem('flow-results', JSON.stringify(storedResults));
    }
  }
}, [results, readOnly, initialized]);

useEffect(() => {
  if (initialized) {
    const storedUserInput = Object.entries(userInput).reduce((acc, [id, value]) => {
      acc[id] = stripHtmlTags(value);
      return acc;
    }, {});
    if (!readOnly) {
      localStorage.setItem('flow-user-input', JSON.stringify(storedUserInput));
    }
  }
}, [userInput, readOnly, initialized]);

const onConnect = useCallback((params) => {
  if (readOnly) return;
  const newEdge = {
    ...params,
    id: `e${params.source}-${params.target}-${Date.now()}`,
    type: 'default',
    style: { strokeWidth: 5 },
  };
  console.log('Connecting edge:', newEdge); // Debugging information
  setEdgesState((prev) => {
    const updatedEdges = [...prev, newEdge];
    localStorage.setItem('flow-edges', JSON.stringify(updatedEdges));
    return updatedEdges;
  });
  dispatch(addEdge([newEdge]));
  setZustandEdges((prev) => [...prev, newEdge]);
}, [edges, dispatch, setEdgesState, setZustandEdges, readOnly]);

const onDelete = useCallback((id) => {
  if (readOnly) return;
  const newNodes = nodes.filter((node) => node.id !== id);
  const newEdges = edges.filter((edge) => edge.source !== id && edge.target !== id);
  setNodesState(newNodes);
  setEdgesState(newEdges);
  dispatch(setNodes(newNodes));
  dispatch(setEdges(newEdges));
  setZustandNodes(newNodes);
  setZustandEdges(newEdges);

  localStorage.setItem('flow-nodes', JSON.stringify(newNodes));
  localStorage.setItem('flow-edges', JSON.stringify(newEdges));

  const storedResults = JSON.parse(localStorage.getItem('flow-results')) || {};
  delete storedResults[id];
  localStorage.setItem('flow-results', JSON.stringify(storedResults));

  const storedUserInput = JSON.parse(localStorage.getItem('flow-user-input')) || {};
  delete storedUserInput[id];
  localStorage.setItem('flow-user-input', JSON.stringify(storedUserInput));

  dispatch(setUserInput({ id, value: '' }));
}, [nodes, edges, setNodesState, setEdgesState, dispatch, setZustandNodes, setZustandEdges, readOnly]);

const handleDrop = useCallback((item, monitor) => {
  if (readOnly) return;
  const triggerNode = dropdownNodeId ? nodes.find(node => node.id === dropdownNodeId) : nodes[nodes.length - 1];

  const getRightHandlePosition = (node) => {
    const nodeWidth = node.type === 'generatedNode' ? GENERATED_NODE_WIDTH : NODE_WIDTH;
    return {
      x: node.position.x + nodeWidth,
      y: node.position.y + 50
    };
  };

  const triggerHandlePos = getRightHandlePosition(triggerNode);
  let newPosition = {
    x: triggerHandlePos.x + NODE_GAP,
    y: triggerHandlePos.y + 50
  };

  while (nodes.some(node => node.position.x === newPosition.x && node.position.y === newPosition.y)) {
    newPosition.y += VERTICAL_GAP;
  }

  if (item.type === 'USER_INPUT') {
    const nodeId = generateNodeId(item.type, nodes.length);

    const newUserInputNode = {
      id: nodeId,
      type: 'userInputNode',
      position: newPosition,
      data: {
        ...item.data,
        id: nodeId,
        input: '',
        color: item.data.color,
        onDelete: () => onDelete(nodeId),
        toggleDropdown,
        onViewResult,
        onExecute: (input) => handleExecuteScenario(input, nodeId),
        isLastNode: true,
      },
    };

    const updatedNodes = updateLastNodeFlag([...nodes, newUserInputNode]);
    setNodesState(updatedNodes);
    setZustandNodes(updatedNodes);
    dispatch(addNode(newUserInputNode));

    const newEdge = {
      id: `e${triggerNode.id}-${newUserInputNode.id}-${Date.now()}`,
      source: triggerNode.id,
      target: newUserInputNode.id,
      type: 'default',
      style: { strokeWidth: 5 },
    };
    console.log('Adding edge:', newEdge); // Debugging information
    setEdgesState((prev) => [...prev, newEdge]);
    setZustandEdges((prev) => [...prev, newEdge]);
    dispatch(addEdge([newEdge]));

    setShowDropdown(false);
  } else {
    const newNodes = (item.modules ? item.modules : [item]).map((module, index) => {
      const nodeId = generateNodeId(item.type, nodes.length + index);
      let nodePosition = {
        x: newPosition.x + index * (NODE_WIDTH + NODE_GAP),
        y: newPosition.y,
      };

      while (nodes.some(node => node.position.x === nodePosition.x && node.position.y === nodePosition.y)) {
        nodePosition.y += VERTICAL_GAP;
      }

      return {
        id: nodeId,
        type: 'customNode',
        position: nodePosition,
        data: {
          label: module.data.label,
          category: module.data.category,
          id: module.id,
          description: module.data.description,
          icon: module.data.icon,
          color: module.data.color,
          onDelete: () => onDelete(nodeId),
          toggleDropdown,
          onViewResult,
          onExecute: (input) => handleExecuteScenario(input || module.data.description, nodeId),
          isLastNode: true,
          getConnectedNodeResults: (nodeId) => getConnectedNodeResults(nodeId),
        },
      };
    });

    const updatedNodes = updateLastNodeFlag([...nodes, ...newNodes]);
    setNodesState(updatedNodes);
    setZustandNodes(updatedNodes);
    dispatch(addNode(newNodes));

    const newEdges = [];

    if (newNodes.length > 0) {
      const firstNewNode = newNodes[0];

      newEdges.push({
        id: `e${triggerNode.id}-${firstNewNode.id}-${Date.now()}`,
        source: triggerNode.id,
        target: firstNewNode.id,
        type: 'default',
        style: { strokeWidth: 5 },
      });

      for (let i = 0; i < newNodes.length - 1; i++) {
        newEdges.push({
          id: `e${newNodes[i].id}-${newNodes[i + 1].id}-${Date.now()}`,
          source: newNodes[i].id,
          target: newNodes[i + 1].id,
          type: 'default',
          style: { strokeWidth: 5 },
        });
      }
    }

    dispatch(addEdge(newEdges));
    setEdgesState((prev) => [...prev, ...newEdges]);
    setZustandEdges((prev) => [...prev, ...newEdges]);

    setShowDropdown(false);
  }
}, [nodes, edges, dispatch, setNodesState, setZustandNodes, setEdgesState, setZustandEdges, readOnly, dropdownNodeId, handleExecuteScenario, toggleDropdown, onDelete, onViewResult]);

const onNodeDragStop = useCallback((_, node) => {
  const updatedNodes = nodes.map(n => (n.id === node.id ? { ...n, position: node.position } : n));
  setNodesState(updatedNodes);
  setZustandNodes(updatedNodes);
  dispatch(setNodes(updatedNodes));
  localStorage.setItem('flow-nodes', JSON.stringify(updatedNodes));
}, [nodes, setNodesState, setZustandNodes, dispatch]);

const onEdgeClick = (event, edge) => {
  event.stopPropagation();
  if (readOnly) return;
  setSelectedEdge(edge); // Set the selected edge
  setModalIsOpen(true); // Open the modal
};

const handleDeleteEdge = () => {
  if (readOnly) return;
  const newEdges = edges.filter(e => e.id !== selectedEdge.id);
  setEdgesState(newEdges);
  dispatch(setEdges(newEdges));
  setZustandEdges(newEdges);
  setModalIsOpen(false); // Close the modal
};

const [, drop] = useDrop({
  accept: ['USER_INPUT', 'SCENARIO', 'MODULE', 'ACTION'],
  drop: handleDrop,
});

const containerRef = useRef(null);

useEffect(() => {
  if (containerRef.current) {
    containerRef.current.style.width = '100%';
    containerRef.current.style.height = '100%';
  }
}, []);

const handleViewResult = (nodeId) => {
  console.log('Viewing result for node:', nodeId);
  setActiveTab(nodeId);
  if (editorRef.current) {
    editorRef.current.setActiveTab(nodeId);
  }
};

const clearCanvas = () => {
  const initialUserInputNode = {
    id: 'user-input-node',
    type: 'userInputNode',
    position: { x: 1000, y: 300 },
    data: { label: 'User Input', category: 'input', id: 'user-input-node', toggleDropdown, onViewResult, isLastNode: true },
  };

  const initialNodes = [initialUserInputNode];
  setNodesState(initialNodes);
  setEdgesState([]);
  dispatch(setNodes(initialNodes));
  dispatch(setEdges([]));
  setZustandNodes(initialNodes);
  setZustandEdges([]);

  localStorage.setItem('flow-nodes', JSON.stringify(initialNodes));
  localStorage.setItem('flow-edges', JSON.stringify([]));
  localStorage.setItem('flow-results', JSON.stringify({}));
  localStorage.setItem('flow-user-input', JSON.stringify({}));
};

const nodesWithEdges = nodes.map(node => ({
  ...node,
  data: {
    ...node.data,
    edges: edges,
    nodes: nodes,
  }
}));

// Add this useEffect to handle module clicks
useEffect(() => {
  const handleModuleClick = (event) => {
    if (readOnly) return;
    
    const { item } = event.detail;
    
    // Use the same positioning logic as handleDrop
    const triggerNode = dropdownNodeId ? nodes.find(node => node.id === dropdownNodeId) : nodes[nodes.length - 1];

    const getRightHandlePosition = (node) => {
      const nodeWidth = node.type === 'generatedNode' ? GENERATED_NODE_WIDTH : NODE_WIDTH;
      return {
        x: node.position.x + nodeWidth,
        y: node.position.y + 50
      };
    };

    const triggerHandlePos = getRightHandlePosition(triggerNode);
    let newPosition = {
      x: triggerHandlePos.x + NODE_GAP,
      y: triggerHandlePos.y + 50
    };

    while (nodes.some(node => node.position.x === newPosition.x && node.position.y === newPosition.y)) {
      newPosition.y += VERTICAL_GAP;
    }

    if (item.type === 'USER_INPUT') {
      const nodeId = generateNodeId(item.type, nodes.length);

      const newUserInputNode = {
        id: nodeId,
        type: 'userInputNode',
        position: newPosition,
        data: {
          ...item.data,
          id: nodeId,
          input: '',
          color: item.data.color,
          onDelete: () => onDelete(nodeId),
          toggleDropdown,
          onViewResult,
          onExecute: (input) => handleExecuteScenario(input, nodeId),
          isLastNode: true,
        },
      };

      const updatedNodes = updateLastNodeFlag([...nodes, newUserInputNode]);
      setNodesState(updatedNodes);
      setZustandNodes(updatedNodes);
      dispatch(addNode(newUserInputNode));

      const newEdge = {
        id: `e${triggerNode.id}-${newUserInputNode.id}-${Date.now()}`,
        source: triggerNode.id,
        target: newUserInputNode.id,
        type: 'default',
        style: { strokeWidth: 5 },
      };

      setEdgesState(prev => [...prev, newEdge]);
      setZustandEdges(prev => [...prev, newEdge]);
      dispatch(addEdge([newEdge]));
    } else {
      const newNodes = (item.modules ? item.modules : [item]).map((module, index) => {
        const nodeId = generateNodeId(item.type, nodes.length + index);
        let nodePosition = {
          x: newPosition.x + index * (NODE_WIDTH + NODE_GAP),
          y: newPosition.y,
        };

        while (nodes.some(node => node.position.x === nodePosition.x && node.position.y === nodePosition.y)) {
          nodePosition.y += VERTICAL_GAP;
        }

        return {
          id: nodeId,
          type: 'customNode',
          position: nodePosition,
          data: {
            label: module.data.label,
            category: module.data.category,
            id: module.id,
            description: module.data.description,
            icon: module.data.icon,
            color: module.data.color,
            onDelete: () => onDelete(nodeId),
            toggleDropdown,
            onViewResult,
            onExecute: (input) => handleExecuteScenario(input || module.data.description, nodeId),
            isLastNode: true,
            getConnectedNodeResults: (nodeId) => getConnectedNodeResults(nodeId),
          },
        };
      });

      const updatedNodes = updateLastNodeFlag([...nodes, ...newNodes]);
      setNodesState(updatedNodes);
      setZustandNodes(updatedNodes);
      dispatch(addNode(newNodes));

      const newEdges = [];
      if (newNodes.length > 0) {
        const firstNewNode = newNodes[0];
        newEdges.push({
          id: `e${triggerNode.id}-${firstNewNode.id}-${Date.now()}`,
          source: triggerNode.id,
          target: firstNewNode.id,
          type: 'default',
          style: { strokeWidth: 5 },
        });

        for (let i = 0; i < newNodes.length - 1; i++) {
          newEdges.push({
            id: `e${newNodes[i].id}-${newNodes[i + 1].id}-${Date.now()}`,
            source: newNodes[i].id,
            target: newNodes[i + 1].id,
            type: 'default',
            style: { strokeWidth: 5 },
          });
        }
      }

      dispatch(addEdge(newEdges));
      setEdgesState(prev => [...prev, ...newEdges]);
      setZustandEdges(prev => [...prev, ...newEdges]);
    }

    setShowDropdown(false);
  };

  document.addEventListener('moduleClick', handleModuleClick);
  return () => document.removeEventListener('moduleClick', handleModuleClick);
}, [
  nodes,
  edges,
  dispatch,
  setNodesState,
  setZustandNodes,
  setEdgesState,
  setZustandEdges,
  readOnly,
  dropdownNodeId,
  handleExecuteScenario,
  toggleDropdown,
  onDelete,
  onViewResult,
  getConnectedNodeResults
]);
return (
  <div id="reactflow-wrapper" ref={drop} style={{ height: '92vh', width: '100%', position: 'relative' }}>
    {!readOnly && <Toolbar user={user} showDropdown={showDropdown} toggleDropdown={toggleDropdown} onNewProject={clearCanvas} />}
    <div ref={containerRef} style={{ height: '92vh', width: '100%' }}>
      <ReactFlow
        key={updateTrigger}
        nodes={nodesWithEdges}
        onNodesChange={onNodesChange}
        onNodeDragStop={onNodeDragStop}
        edges={edges}
        onInit={onInit} // NEW: Attach the onInit callback
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onEdgeClick={onEdgeClick}
        nodeTypes={nodeTypes}
        defaultEdgeOptions={{ type: 'default', style: { strokeWidth: 5 } }}
        minZoom={0.1}
        defaultZoom={1}
        maxZoom={10}
      >
        <MiniMap />
        <Controls />
        <Background />
      </ReactFlow>
    </div>
    <DeleteConnectionModal
      isOpen={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
      onDelete={handleDeleteEdge}
    />
  </div>
);
};

export default FlowCanvas;
