import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, TextField, Button, Typography, IconButton, InputAdornment, CircularProgress, Tooltip
} from '@mui/material';
import { Visibility, VisibilityOff, Email, Lock } from '@mui/icons-material';
import GoogleIcon from '@mui/icons-material/Google';
import {
  getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, sendPasswordResetEmail
} from "firebase/auth";
import { v4 as uuidv4 } from 'uuid';
import { supabase } from '../supabaseClient';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  const togglePasswordVisibility = () => setPasswordShown(prev => !prev);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      if (!validateEmail(email)) {
        toast.error("Please enter a valid email address.");
        return;
      }

      try {
        // Attempt to sign in
        await signInWithEmailAndPassword(auth, email, password);
        toast.success('Login successful');
        navigate('/');
      } catch (loginError) {
        if (loginError.code === 'auth/user-not-found') {
          toast.error('No account found with this email. Please sign up first.');
        } else {
          handleFirebaseLoginErrors(loginError);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async () => {
    setLoading(true);
    try {
      if (!validateEmail(email)) {
        toast.error("Please enter a valid email address.");
        return;
      }

      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        const uuid = uuidv4();

        const { error: insertError } = await supabase.from('users').insert([
          { id: uuid, email: user.email, credits: 10, firebase_uid: user.uid }
        ]);

        if (insertError) {
          console.error("Error inserting user into Supabase:", insertError.message);
          toast.error('Failed to save user information. Please try again.');
        } else {
          toast.success('Account created successfully, redirecting...');
          navigate('/onboarding');
        }
      } catch (signUpError) {
        handleFirebaseSignUpErrors(signUpError);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFirebaseLoginErrors = (error) => {
    console.error("Login Error:", error);
    switch (error.code) {
      case 'auth/wrong-password':
        toast.error('Incorrect password. Please try again.');
        break;
      case 'auth/user-disabled':
        toast.error('Your account has been disabled. Contact support.');
        break;
      case 'auth/invalid-email':
        toast.error('Invalid email address.');
        break;
      default:
        toast.error('Failed to log in. Please signup.');
    }
  };

  const handleFirebaseSignUpErrors = (error) => {
    console.error("SignUp Error:", error);
    switch (error.code) {
      case 'auth/email-already-in-use':
        toast.error('An account with this email already exists.');
        break;
      case 'auth/invalid-email':
        toast.error('Invalid email address.');
        break;
      case 'auth/weak-password':
        toast.error('Weak password. Please use a stronger password.');
        break;
      default:
        toast.error('Failed to create account. Please try again.');
    }
  };

  const handleGoogleLogin = async () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Check if the user exists in the Supabase DB
      const { data: existingUser, error } = await supabase.from('users').select('*').eq('firebase_uid', user.uid).single();

      if (error || !existingUser) {
        // If user doesn't exist, insert them into the Supabase DB
        const uuid = uuidv4();
        const { error: insertError } = await supabase.from('users').insert([
          { id: uuid, email: user.email, credits: 10, firebase_uid: user.uid }
        ]);

        if (insertError) {
          console.error("Error inserting user into Supabase:", insertError.message);
          toast.error('Failed to save user information. Please try again.');
        } else {
          toast.success('Account created successfully, redirecting...');
          navigate('/onboarding');
        }
      } else {
        toast.success('Google login successful');
        navigate('/');
      }
    } catch (error) {
      console.error('Error logging in with Google:', error.message);
      toast.error('Failed to log in with Google. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    if (!validateEmail(email)) {
      toast.error('Please enter a valid email address.');
      return;
    }

    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success('Password reset email sent. Please check your inbox.');
      setForgotPassword(false); // Switch back to login form after requesting reset
    } catch (error) {
      console.error('Error sending password reset email:', error.message);
      toast.error('Failed to send password reset email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        textAlign: 'center',
        padding: 4,
        background: 'linear-gradient(to bottom, #f9fafb, #ffffff)',
        fontFamily: 'sans-serif',
      }}
    >
      <ToastContainer />
      <Box 
        sx={{
          maxWidth: '400px',
          width: '100%',
          backgroundColor: 'white',
          borderRadius: '24px',
          padding: 4,
          boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Typography variant="h4" sx={{ color: '#1f2937', fontWeight: 800, mb: 2 }}>
          {forgotPassword ? 'Reset Password' : isSignUp ? 'Create an Account' : 'Welcome Back'}
        </Typography>
        <Typography variant="body1" sx={{ color: '#6b7280', mb: 4 }}>
          {forgotPassword ? 'Enter your email to reset your password' : isSignUp ? 'Sign up to get started' : 'Log in to your account'}
        </Typography>
        <Box component="form">
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
          />
          {!forgotPassword && (
            <TextField
              label="Password"
              type={passwordShown ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={passwordShown ? 'Hide Password' : 'Show Password'}>
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {passwordShown ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          )}
          {forgotPassword ? (
            <Button
              variant="contained"
              onClick={handleForgotPassword}
              disabled={loading}
              fullWidth
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: '#4f46e5',
                borderRadius: '12px',
                padding: '12px',
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 600,
                '&:hover': {
                  backgroundColor: '#4338ca',
                },
              }}
            >
              {loading ? <CircularProgress size={24} /> : 'Reset Password'}
            </Button>
          ) : (
            <>
              <Button 
                variant="contained" 
                onClick={isSignUp ? handleSignUp : handleLogin} 
                disabled={loading} 
                fullWidth
                startIcon={<Lock />}
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: '#4f46e5',
                  borderRadius: '12px',
                  padding: '12px',
                  textTransform: 'none',
                  fontSize: '1rem',
                  fontWeight: 600,
                  '&:hover': {
                    backgroundColor: '#4338ca',
                  },
                }}
              >
                {loading ? <CircularProgress size={24} /> : isSignUp ? 'Sign Up' : 'Log In'}
              </Button>
              <Button
                variant="outlined"
                onClick={handleGoogleLogin}
                disabled={loading}
                fullWidth
                startIcon={<GoogleIcon />}
                sx={{
                  mb: 2,
                  borderColor: '#d1d5db',
                  color: '#374151',
                  borderRadius: '12px',
                  padding: '12px',
                  textTransform: 'none',
                  fontSize: '1rem',
                  fontWeight: 600,
                  '&:hover': {
                    backgroundColor: '#f3f4f6',
                    borderColor: '#9ca3af',
                  },
                }}
              >
                {loading ? <CircularProgress size={24} /> : 'Log in with Google'}
              </Button>
              <Box sx={{ mt: 3 }}>
                <Button 
                  onClick={() => setIsSignUp(!isSignUp)} 
                  disabled={loading} 
                  fullWidth
                  sx={{
                    backgroundColor: '#e2e8f0',
                    color: '#4f46e5',
                    textTransform: 'none',
                    fontSize: '0.875rem',
                    fontWeight: 'bold',
                    padding: '10px',
                    borderRadius: '12px',
                    '&:hover': {
                      backgroundColor: '#cbd5e1',
                    },
                  }}
                >
                  {isSignUp ? 'Already have an account? Log in' : "Don't have an account? Sign up"}
                </Button>
                <Button 
                  onClick={() => setForgotPassword(true)} 
                  disabled={loading} 
                  fullWidth
                  sx={{
                    color: '#4f46e5',
                    textTransform: 'none',
                    fontSize: '0.875rem',
                    mt: 2,
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  Forgot Password?
                </Button>
              </Box>
            </>
          )}
          {forgotPassword && (
            <Button 
              onClick={() => setForgotPassword(false)} 
              disabled={loading} 
              fullWidth
              sx={{
                color: '#4f46e5',
                textTransform: 'none',
                fontSize: '0.875rem',
                mt: 2,
              }}
            >
              Back to Login
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
