import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './QuillEditor.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { updateNodeData } from '../slices/nodesSlice';
import { replaceResult } from '../slices/resultsSlice';
import { stripHtmlTags } from '../utils';
import {
  Box, TextField, Typography, Tabs, Tab, Paper, IconButton, Divider, Popper, Fade, Tooltip
} from '@mui/material';
import { Minimize, GetApp } from '@mui/icons-material';
import htmlDocx from 'html-docx-js/dist/html-docx';
import { saveAs } from 'file-saver';
import ExportDialog from './ExportDialog';
import PopupContent from './PopupContent';
import { supabase } from '../supabaseClient';

const QuillEditor = forwardRef((props, ref) => {
  const { onMinimize, activeTab: initialActiveTab } = props;
  const results = useSelector((state) => state.results);
  const nodes = useSelector((state) => state.nodes);
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const [activeTab, setActiveTab] = useState(initialActiveTab || (nodes.length ? nodes[0].id : ''));
  const [label, setLabel] = useState('');
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [selection, setSelection] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [promptOptions, setPromptOptions] = useState([]);
  const [descriptionOpen, setDescriptionOpen] = useState(true);

  useImperativeHandle(ref, () => ({
    setActiveTab,
  }));

  useEffect(() => {
    if (initialActiveTab) {
      setActiveTab(initialActiveTab);
    }
  }, [initialActiveTab]);

  useEffect(() => {
    const updateEditorContent = () => {
      if (editorRef.current) {
        editorRef.current.innerHTML = '';
        const node = nodes.find(node => node.id === activeTab);
        if (node) {
          const nodeResults = results.filter(result => result.id === activeTab);
          let tabContent = nodeResults.map(nodeResult => nodeResult.populatedHtml).join('');
          editorRef.current.innerHTML = tabContent;
          setLabel(node.data.label || '');
        }
      }
    };

    updateEditorContent();
  }, [results, activeTab, nodes]);

  const handleLabelChange = (e) => {
    setLabel(e.target.value);
    const node = nodes.find(node => node.id === activeTab);
    if (node) {
      dispatch(updateNodeData({ id: activeTab, data: { label: e.target.value } }));
    }
  };

  const autosaveContent = () => {
    if (!activeTab) return;
    const content = editorRef.current.innerHTML;
    const strippedContent = stripHtmlTags(content);

    console.log(`autosaveContent: Saving content for node ${activeTab} - Content: ${strippedContent}`);

    // Dispatch actions to update the Redux state
    dispatch(replaceResult({ id: activeTab, result: strippedContent, populatedHtml: content }));
    dispatch(updateNodeData({ id: activeTab, data: { populatedHtml: content } }));

    console.log('autosaveContent: Redux state updated with new content.');
  };

  const handleClickOutside = (event) => {
    if (editorRef.current && !editorRef.current.contains(event.target)) {
      autosaveContent();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [activeTab, label]);

  const handleExport = (selectedTabs, exportFormat) => {
    const selectedTabIds = Object.keys(selectedTabs).filter(tabId => selectedTabs[tabId]);
    if (selectedTabIds.length === 0) {
      console.error('No content to export.');
      return;
    }

    if (selectedTabIds.length === nodes.length - 1) {
      const aggregatedContent = selectedTabIds.map(tabId => {
        const node = nodes.find(node => node.id === tabId);
        const tabName = node.data.label || 'Unnamed Node';
        const nodeResults = results.filter(result => result.id === tabId);
        const contentWithHeading = `<h2>${tabName}</h2>\n\n` + nodeResults.map(result => result.populatedHtml).join('\n\n');
        return contentWithHeading;
      }).join('\n\n');

      const blob = htmlDocx.asBlob(aggregatedContent);
      saveAs(blob, `export_all.${exportFormat}`);
    } else {
      selectedTabIds.forEach(tabId => {
        const node = nodes.find(node => node.id === tabId);
        const tabName = node.data.label || 'Unnamed Node';
        const nodeResults = results.filter(result => result.id === tabId);
        const exportContent = `<h2>${tabName}</h2>\n\n` + nodeResults.map(result => result.populatedHtml).join('\n\n');

        if (exportContent) {
          const blob = htmlDocx.asBlob(exportContent);
          saveAs(blob, `${tabName}.${exportFormat}`);
        }
      });
    }
  };

  const handleTextSelect = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const selectedText = range.toString();
      if (selectedText) {
        const rangeRect = range.getBoundingClientRect();
        setSelection(selectedText);
        setAnchorEl({
          getBoundingClientRect: () => rangeRect
        });
        setPopupOpen(true);
      }
    }
  };

  const fetchPromptOptions = async () => {
    try {
      const { data, error } = await supabase
        .from('microprompts')
        .select('*');

      if (error) {
        console.error('Error fetching prompt options:', error);
        return [];
      }

      setPromptOptions(data);
    } catch (error) {
      console.error('Error fetching prompt options:', error);
      return [];
    }
  };

  useEffect(() => {
    fetchPromptOptions();
  }, []);

  const handlePopupClose = () => {
    setPopupOpen(false);
    setSelection(null);
  };

  const handleSaveResponse = (response) => {
    if (response && selection) {
      const content = editorRef.current.innerHTML;
      const updatedContent = content.replace(selection, response);
      editorRef.current.innerHTML = updatedContent;
      autosaveContent();
    }
    handlePopupClose();
  };

  return (
    <Paper elevation={3} className="editor-container">
      <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
        <Box display="flex" alignItems="center">
          <Tooltip title="Minimize" arrow>
            <IconButton color="inherit" onClick={onMinimize} aria-label="minimize" className="minimize-button">
              <Minimize />
            </IconButton>
          </Tooltip>
          <Typography variant="body1" className="header-description">
            Easily create, edit, and format your content. Save your progress, export in various formats, and enjoy a seamless editing experience. Start creating something amazing!
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Tabs
  value={activeTab}
  onChange={(e, newValue) => setActiveTab(newValue)}
  indicatorColor="primary"
  textColor="primary"
  variant="scrollable"
  scrollButtons="auto"
  className="tabs"
>
  {nodes
    .filter(node => node.id !== 'sm1' && node.data.label !== 'Generate New Ideas' && node.type !== 'userInputNode')
    .map((node) => (
      <Tab key={node.id} label={node.data.label || 'Unnamed Node'} value={node.id} />
    ))}
</Tabs>

      <Box p={2} className="editor-body" onMouseUp={handleTextSelect} onKeyUp={handleTextSelect}>
        {activeTab && (
          <TextField
            label="Node Label"
            value={label}
            onChange={handleLabelChange}
            fullWidth
            margin="normal"
            variant="outlined"
            sx={{ backgroundColor: 'white', borderRadius: '4px' }}
          />
        )}
        <Box ref={editorRef} className="html-editor" contentEditable={true} p={2} />
      </Box>

      <ExportDialog
        open={exportDialogOpen}
        onClose={() => setExportDialogOpen(false)}
        nodes={nodes}
        onExport={handleExport}
      />

      <Popper open={popupOpen} anchorEl={anchorEl} transition disablePortal>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper', zIndex: 2000 }}>
              <PopupContent
                selectedText={selection || ''}
                promptOptions={promptOptions}
                onSave={handleSaveResponse}
                onClose={handlePopupClose}
              />
            </Box>
          </Fade>
        )}
      </Popper>
    </Paper>
  );
});

export default QuillEditor;
