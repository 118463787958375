// resultsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const resultsSlice = createSlice({
  name: 'results',
  initialState,
  reducers: {
    addResult: (state, action) => {
      state.push(action.payload);
    },
    setResults: (state, action) => {
      return action.payload;
    },
    updateResult: (state, action) => {
      const { id, result, populatedHtml } = action.payload;
      const resultIndex = state.findIndex(res => res.id === id);
      if (resultIndex !== -1) {
        state[resultIndex] = { ...state[resultIndex], result, populatedHtml };
      } else {
        state.push({ id, result, populatedHtml });
      }
    },
    appendResult: (state, action) => {
      const { id, result } = action.payload;
      const resultIndex = state.findIndex(res => res.id === id);
      if (resultIndex !== -1) {
        state[resultIndex].result += result;
      } else {
        state.push({ id, result });
      }
    },
    replaceResult: (state, action) => {
      const { id, result, populatedHtml } = action.payload;
      const resultIndex = state.findIndex(res => res.id === id);
      if (resultIndex !== -1) {
        state[resultIndex] = { id, result, populatedHtml };
      } else {
        state.push({ id, result, populatedHtml });
      }
    },
  },
});

export const { addResult, setResults, updateResult, appendResult, replaceResult } = resultsSlice.actions;
export default resultsSlice.reducer;