import React from 'react';
import {
  Box, IconButton, Avatar, Menu, MenuItem,
  Dialog, DialogTitle, DialogContent, DialogActions, Button
} from '@mui/material';
import EditAccount from './EditAccount';
import Usage from './Usage';
import { getAuth, signOut as firebaseSignOut } from "firebase/auth";
import './ProfileMenu.css';

const ProfileMenu = ({
  user, anchorEl, openEditAccount, openUsage, handleProfileMenuOpen,
  handleProfileMenuClose, handleEditAccountOpen, handleUsageOpen,
  handleDialogClose
}) => {
  const auth = getAuth();

  const handleSignOut = async () => {
    try {
      await firebaseSignOut(auth);
      window.location.href = '/login'; // Redirect to login page after sign out
    } catch (error) {
      console.error("Error signing out:", error);
      alert("Failed to log out. Please try again.");
    }
  };

  return (
    <Box className="profile-menu">
      <IconButton onClick={handleProfileMenuOpen}>
        <Avatar>{user.email.charAt(0).toUpperCase()}</Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleProfileMenuClose}
      >
        <MenuItem onClick={handleEditAccountOpen}>Edit Account</MenuItem>
        <MenuItem onClick={handleUsageOpen}>Add More Credits</MenuItem>
        <MenuItem onClick={handleSignOut}>Log out</MenuItem>
      </Menu>
      <Dialog open={openEditAccount} onClose={handleDialogClose}>
        <DialogTitle>Edit Account</DialogTitle>
        <DialogContent>
          <EditAccount user={user} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openUsage} onClose={handleDialogClose}>
        <DialogTitle>Add More Credits</DialogTitle>
        <DialogContent>
          <Usage user={user} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default ProfileMenu;
