import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, Button, Grid, Typography, Box } from '@mui/material';
import { ShoppingCart, Zap } from 'lucide-react';
import { styled } from '@mui/material/styles';
import { supabase } from '../supabaseClient';
import { toast } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation } from 'react-router-dom';

const stripePromise = loadStripe('pk_live_51NmAfJBwEHUAa7JM0xwxep0Uu0YnqfdSTPzG282HiqNBiH43pvmw9aciQ4hJgWgvN72neDgJfT02QeSszEoPqKsd00kvzvfj8A');

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
  },
}));

const pricingModels = [
  { name: 'Basic', modelKey: 'basic', credits: 20, price: 5.00 },
  { name: 'Standard', modelKey: 'standard', credits: 75, price: 12.00 },
  { name: 'Premium', modelKey: 'premium', credits: 175, price: 25.00 },
];

const Usage = ({ user }) => {
  const [credits, setCredits] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const fetchCredits = async () => {
      const { data, error } = await supabase
        .from('users')
        .select('credits')
        .eq('id', user.id)
        .single();

      if (error) {
        toast.error('Error fetching credits');
      } else {
        setCredits(data.credits);
      }
    };

    fetchCredits();
  }, [user.id]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('success')) {
      toast.success('Payment successful! Credits have been added to your account.');
    }
  }, [location]);

  const handleTopUp = async (modelKey) => {
    const stripe = await stripePromise;
    try {
      const response = await fetch('https://us-central1-tinktide.cloudfunctions.net/payment/create-checkout-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ pricingModel: modelKey, userId: user.id }),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const session = await response.json();
      const result = await stripe.redirectToCheckout({ sessionId: session.id });

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', padding: 4 }}>
      <StyledCard sx={{ marginBottom: 4 }}>
        <CardHeader
          title={
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h4" fontWeight="bold" color="text.primary">Your Credits</Typography>
              <Box display="flex" alignItems="center">
                <Zap color="#6200EA" size={32} />
                <Typography variant="h3" fontWeight="bold" color="#6200EA" ml={1}>
                  {credits}
                </Typography>
              </Box>
            </Box>
          }
        />
      </StyledCard>

      <Typography variant="h5" fontWeight="bold" mb={3} color="text.primary">Top Up Your Credits</Typography>
      
      <Grid container spacing={3}>
        {pricingModels.map((model) => (
          <Grid item xs={12} sm={6} key={model.modelKey}>
            <StyledCard sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              
              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box>
                <Typography variant="h4" fontWeight="bold" mb={1} color="text.primary">${model.price.toFixed(2)}</Typography>
                <Typography variant="body1" color="text.secondary">{model.credits} Credits</Typography>
                </Box>
                <Button
                  variant="contained"
                  onClick={() => handleTopUp(model.modelKey)}
                  startIcon={<ShoppingCart />}
                  fullWidth
                  sx={{
                    mt: 2,
                    bgcolor: '#6200EA',
                    '&:hover': { bgcolor: '#7C4DFF' },
                  }}
                >
                  Buy Now
                </Button>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Usage;