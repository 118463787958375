import React, { useState } from 'react';
import { Button, Box, Typography, Card, CardMedia, MobileStepper, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowRight, ArrowLeft } from 'lucide-react';

const steps = [
  {
    label: 'Start with Your Idea',
    description: 'Define your project or thought process and bring tools into your canvas.',
    points: [
      'Type your main idea or goal',
      'Drag and drop relevant tools onto the canvas',
      'Click "Run" or "View" to initiate or review your results',
    ],
  },
  {
    label: 'Connect and Expand',
    description: 'Link tools to build a cohesive structure with generated content.',
    points: [
      'Connect different tools or nodes on the canvas',
      'Explore content generated by each tool’s framework',
      'Visualize relationships and make thinking easier',
    ],
  },
  {
    label: 'Refine and Discover',
    description: 'Edit content and use Quickfire to generate additional insights.',
    points: [
      'Edit content directly within any node or tool',
      'Use Quickfire to generate new ideas or understand concepts',
      'Refine your thinking map until it’s complete',
    ],
  },
];

const Onboarding = () => {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const maxSteps = steps.length;

  const handleNext = () => {
    if (activeStep === maxSteps - 1) {
      navigate('/'); // Redirect to the main application on the last step
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: { xs: 2, md: 4 },
        backgroundColor: 'white',
      }}
    >
      <Card
        sx={{
          width: '100%',
          maxWidth: '1000px',
          boxShadow: 6,
          borderRadius: '20px',
          padding: { xs: 3, md: 5 },
          backgroundColor: 'white',
        }}
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              sx={{
                color: '#1f2937',
                fontWeight: 800,
                mb: 2,
                textAlign: { xs: 'center', md: 'left' },
                fontSize: { xs: '1.5rem', md: '2rem' },
              }}
            >
              {steps[activeStep].label}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: '#6b7280',
                mb: 3,
                textAlign: { xs: 'center', md: 'left' },
                fontSize: { xs: '1rem', md: '1.25rem' },
              }}
            >
              {steps[activeStep].description}
            </Typography>
            <ul style={{ listStyle: 'none', paddingLeft: 0, color: '#374151', marginBottom: '20px' }}>
              {steps[activeStep].points.map((point, index) => (
                <li key={index} style={{ marginBottom: '8px' }}>
                  <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                    <span
                      style={{
                        display: 'inline-block',
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        backgroundColor: '#4f46e5',
                        marginRight: '10px',
                      }}
                    />
                    {point}
                  </Typography>
                </li>
              ))}
            </ul>
          </Grid>

          {/* Video Section */}
          <Grid item xs={12} md={6}>
            <CardMedia
              component="video"
              src="/tink-tide.webm"
              autoPlay
              controls
              sx={{
                borderRadius: '20px',
                maxHeight: { xs: '200px', md: '400px' },
                objectFit: 'cover',
                width: '100%',
              }}
            />
          </Grid>
        </Grid>

        <MobileStepper
          variant="dots"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          sx={{ justifyContent: 'center', mt: 4, width: '100%' }}
          nextButton={
            <Button
              size="large"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
              sx={{
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 600,
                color: 'white',
                backgroundColor: '#4f46e5',
                borderRadius: '9999px',
                padding: '12px 24px',
                boxShadow: '0px 10px 15px -3px rgba(59, 130, 246, 0.4)',
                '&:hover': {
                  backgroundColor: '#4338ca',
                },
              }}
            >
              Next
              <ArrowRight style={{ marginLeft: '8px' }} />
            </Button>
          }
          backButton={
            <Button
              size="large"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 600,
                color: '#4f46e5',
                backgroundColor: 'white',
                borderRadius: '9999px',
                padding: '12px 24px',
                border: '1px solid #4f46e5',
                '&:hover': {
                  backgroundColor: '#f3f4f6',
                },
              }}
            >
              <ArrowLeft style={{ marginRight: '8px' }} />
              Back
            </Button>
          }
        />

        {activeStep === maxSteps - 1 && (
          <Button
            variant="contained"
            onClick={handleNext}
            sx={{
              mt: 4,
              backgroundColor: '#4f46e5',
              borderRadius: '9999px',
              padding: '14px 24px',
              textTransform: 'none',
              fontSize: '1.125rem',
              fontWeight: 700,
              color: 'white',
              boxShadow: '0px 10px 15px -3px rgba(59, 130, 246, 0.4)',
              '&:hover': {
                backgroundColor: '#4338ca',
                boxShadow: '0px 15px 25px -5px rgba(37, 99, 235, 0.5)',
              },
            }}
          >
            Get Started
          </Button>
        )}
      </Card>
    </Box>
  );
};

export default Onboarding;
