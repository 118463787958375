import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel,
  Checkbox, Button, FormControl, FormLabel, RadioGroup, Radio, Divider,
  Typography, Box, Paper
} from '@mui/material';

const ExportDialog = ({ open, onClose, nodes, onExport }) => {
  const [selectedTabs, setSelectedTabs] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [exportFormat, setExportFormat] = useState('docx');

  useEffect(() => {
    if (open) {
      const initialSelectedTabs = nodes.reduce((acc, node) => ({ ...acc, [node.id]: false }), {});
      setSelectedTabs(initialSelectedTabs);
      setSelectAll(false);
    }
  }, [open, nodes]);

  const handleCheckboxChange = (id) => {
    setSelectedTabs(prevState => {
      const updatedTabs = { ...prevState, [id]: !prevState[id] };
      const allSelected = Object.values(updatedTabs).every(value => value);
      setSelectAll(allSelected);
      return updatedTabs;
    });
  };

  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedTabs(nodes.reduce((acc, node) => ({ ...acc, [node.id]: newSelectAll }), {}));
  };

  const handleExport = () => {
    const selectedNodes = nodes.filter(node => selectedTabs[node.id]);
    const fileName = selectedNodes.map(node => node.data.label || 'Unnamed Node').join('_') || 'Export';
    onExport(selectedTabs, exportFormat, fileName);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { p: 3, boxShadow: 4, borderRadius: 2 } }}>
      <DialogTitle>
        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
          Select Tabs to Export
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectAll}
              onChange={handleSelectAllChange}
              color="primary"
              sx={{ transform: 'scale(1.2)' }}
            />
          }
          label={<Typography variant="body1">Select All</Typography>}
        />
        <Divider sx={{ my: 2 }} />
        <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
          {nodes.filter(node => node.type !== 'userInputNode').map((node) => (
            <Paper
              key={node.id}
              elevation={1}
              sx={{ mb: 1, p: 2, display: 'flex', alignItems: 'center', boxShadow: 2, borderRadius: 1 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedTabs[node.id] || false}
                    onChange={() => handleCheckboxChange(node.id)}
                    color="primary"
                    sx={{ transform: 'scale(1.1)' }}
                  />
                }
                label={<Typography variant="body2">{node.data.label || 'Unnamed Node'}</Typography>}
              />
            </Paper>
          ))}
        </Box>
        <FormControl component="fieldset" sx={{ mt: 2 }}>
          <FormLabel component="legend">Export Format</FormLabel>
          <RadioGroup
            aria-label="export-format"
            name="export-format"
            value={exportFormat}
            onChange={(e) => setExportFormat(e.target.value)}
          >
            <FormControlLabel value="docx" control={<Radio />} label="DOCX" />
            {/* Add more export formats as needed */}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" sx={{ boxShadow: 2 }}>
          Cancel
        </Button>
        <Button onClick={handleExport} variant="contained" color="primary" sx={{ boxShadow: 3 }}>
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;
