// src/firebase.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";  // Import Firebase Authentication
import { getAnalytics } from "firebase/analytics";  // Optional, if you need analytics

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDLAusIH0Nkl1TdUDKYCA1C28Rmney6U3E",
  authDomain: "tinktide.firebaseapp.com",
  projectId: "tinktide",
  storageBucket: "tinktide.appspot.com",
  messagingSenderId: "278227421063",
  appId: "1:278227421063:web:78856854cecaecc885b522",
  measurementId: "G-MXGBCFVLZG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Initialize Analytics (optional)
const analytics = getAnalytics(app);

export { auth, analytics };  // Export the auth object to be used in your app
