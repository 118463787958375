import { create } from 'zustand';

const useStore = create((set) => ({
  nodes: [],
  edges: [],
  setNodes: (nodes) => set({ nodes }),
  setEdges: (edges) => set({ edges }),
  addNode: (newNode) => set((state) => ({
    nodes: [...state.nodes, newNode]
  })),
}));

export default useStore;