import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateResult } from '../slices/resultsSlice';
import { setNodes, updateNodeData, addNode, removeNode } from '../slices/nodesSlice';
import { setEdges } from '../slices/edgesSlice';
import { executePrompt } from '../api';
import { createGeneratedNodes } from '../components/FlowCanvas';
import { stripHtmlTags } from '../utils';

export const executeScenario = createAsyncThunk(
  'executeScenario',
  async ({ startNodeId, toggleDropdown }, { dispatch, getState }) => {
    const state = getState();
    const nodes = state.nodes;
    const edges = state.edges;
    const results = state.results;
    const userInput = state.userInput;
    const userId = state.user.id;

    console.log(`executeScenario: Starting execution from node ${startNodeId}`);
    console.log('executeScenario: Initial state:', { nodes, edges, results, userInput });

    const visitedNodes = new Set();
    const queue = [startNodeId];

    while (queue.length > 0) {
      const currentNodeId = queue.shift();
      visitedNodes.add(currentNodeId);

      const connectedNodes = edges
        .filter(edge => edge.source === currentNodeId)
        .map(edge => edge.target);

      for (const nodeId of connectedNodes) {
        if (!visitedNodes.has(nodeId)) {
          queue.push(nodeId);
        }
      }
    }

    const nodesToProcess = nodes.filter(node => visitedNodes.has(node.id));

    let currentInput = null;

    for (let i = 0; i < nodesToProcess.length; i++) {
      const node = nodesToProcess[i];
      try {
        dispatch(updateNodeData({ id: node.id, data: { loading: true } }));

        const nodeData = {
          category: node.data.category,
          moduleId: node.data.moduleId || node.data.id,
        };

        if (node.type === 'userInputNode') {
          currentInput = userInput[node.id] || '';
          console.log(`executeScenario: Retrieved user input for node ${node.id}: ${currentInput}`);
          dispatch(updateNodeData({ id: node.id, data: { loading: false, result: currentInput } }));
          continue;
        }

        const previousNodeId = edges.find(edge => edge.target === node.id)?.source;
        const previousResult = previousNodeId ? results.find(res => res.id === previousNodeId) : null;

        if (previousResult) {
          const plainTextResult = stripHtmlTags(previousResult.populatedHtml);
          currentInput = plainTextResult;
        } else if (node.type === 'generatedNode' && node.data.parentId) {
          const parentResult = results.find(res => res.id === node.data.parentId);
          const plainTextParentResult = parentResult ? stripHtmlTags(parentResult.populatedHtml) : '';
          currentInput = plainTextParentResult || currentInput;
        } else {
          currentInput = node.data.description;
        }

        console.log(`executeScenario: Executing node ${node.id} with input: ${currentInput}`);
        console.log('executeScenario: Node data:', nodeData);

        const response = await executePrompt([nodeData], currentInput, userId); // Pass userId

        const result = response[0];

        console.log(`executeScenario: Received response for node ${node.id}:`, result);

        dispatch(updateResult({ id: node.id, populatedHtml: result.populatedHtml }));
        dispatch(updateNodeData({ id: node.id, data: { loading: false } }));

        currentInput = stripHtmlTags(result.populatedHtml);

        if (result.jsonOutput && nodeData.moduleId === 'sm1') {
          createGeneratedNodes(dispatch, node.id, result.jsonOutput, toggleDropdown, executeScenario, (id) => dispatch(removeNode(id)), nodes, edges);
        }
      } catch (error) {
        console.error('executeScenario: Error executing node', node.id, ':', error);
        dispatch(updateNodeData({ id: node.id, data: { loading: false, error: error.message } }));
        break;
      }
    }
  }
);
