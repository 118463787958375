import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const edgesSlice = createSlice({
  name: 'edges',
  initialState,
  reducers: {
    setEdges: (state, action) => {
      console.log('setEdges:', action.payload); // Debugging
      return action.payload;
    },
    addEdge: (state, action) => {
      console.log('addEdge:', action.payload); // Debugging
      if (Array.isArray(action.payload)) {
        return [...state, ...action.payload];
      } else {
        console.error('Payload is not an array:', action.payload);
        return state; // Return the current state unchanged if payload is not an array
      }
    },
    removeEdge: (state, action) => {
      console.log('removeEdge:', action.payload); // Debugging
      return state.filter(edge => edge.id !== action.payload);
    },
  },
});

export const { setEdges, addEdge, removeEdge } = edgesSlice.actions;
export default edgesSlice.reducer;
