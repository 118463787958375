import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { supabase } from '../supabaseClient';
import { Box, Typography, Button, Tooltip, IconButton, TextField } from '@mui/material';
import { setNodes as setReduxNodes } from '../slices/nodesSlice';
import { setEdges as setReduxEdges } from '../slices/edgesSlice';
import { setResults } from '../slices/resultsSlice';
import { setUserInput } from '../slices/userInputSlice';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import useStore from '../zustandStore';
import './YourWork.css';

const LoadProject = ({ user, setCurrentProjectId, setCurrentProjectName, projects = [], setProjects, onProjectLoaded }) => {
  const [editableProjectId, setEditableProjectId] = useState(null);
  const [newProjectName, setNewProjectName] = useState('');
  const dispatch = useDispatch();
  const setNodes = useStore((state) => state.setNodes);
  const setEdges = useStore((state) => state.setEdges);
  const nodes = useStore((state) => state.nodes);
  const edges = useStore((state) => state.edges);
  const results = useSelector((state) => state.results);
  const userInput = useSelector((state) => state.userInput);

  useEffect(() => {
    const fetchProjects = async () => {
      if (!user) {
        toast.error('User not logged in');
        return;
      }

      const { data, error } = await supabase
        .from('projects')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) {
        toast.error('Error fetching projects');
        console.error('Error fetching projects:', error);
      } else {
        setProjects(data);
      }
    };

    fetchProjects();
  }, [user, setProjects]);

  const loadProject = useCallback(async (projectId) => {
    const { data, error } = await supabase
      .from('projects')
      .select('*')
      .eq('id', projectId)
      .single();

    if (error) {
      toast.error('Error loading project');
      console.error('Error loading project:', error);
    } else {
      toast.success('Project loaded successfully!');
      const { name, data: projectData } = data;
      const { nodes, edges, results, userInput } = projectData;

      // Update Zustand state
      setNodes([...nodes]);
      setEdges([...edges]);

      // Update Redux state
      dispatch(setReduxNodes([...nodes]));
      dispatch(setReduxEdges([...edges]));
      dispatch(setResults([...results]));

      // Set userInput in Redux state
      Object.entries(userInput).forEach(([id, value]) => {
        dispatch(setUserInput({ id, value }));
      });

      setCurrentProjectId(projectId);
      setCurrentProjectName(name); // Update project name

      if (onProjectLoaded) {
        onProjectLoaded(projectId, name, nodes, edges, results, userInput);
      }
    }
  }, [dispatch, setNodes, setEdges, setCurrentProjectId, setCurrentProjectName, onProjectLoaded]);

  const handleLoadProject = async (projectId) => {
    await loadProject(projectId); // Load project once
    await loadProject(projectId); // Load project twice - fix bug
  };

  const renameProject = async (projectId, newName) => {
    if (!newName) {
      toast.error('Project name cannot be empty');
      return;
    }

    const { error } = await supabase
      .from('projects')
      .update({ name: newName })
      .eq('id', projectId);

    if (error) {
      toast.error('Error renaming project');
      console.error('Error renaming project:', error);
    } else {
      toast.success('Project renamed successfully!');
      setProjects(projects.map((project) => 
        project.id === projectId ? { ...project, name: newName } : project
      ));
      setEditableProjectId(null);
    }
  };

  const deleteProject = async (projectId) => {
    const { error } = await supabase
      .from('projects')
      .delete()
      .eq('id', projectId);

    if (error) {
      toast.error('Error deleting project');
      console.error('Error deleting project:', error);
    } else {
      toast.success('Project deleted successfully!');
      setProjects(projects.filter((project) => project.id !== projectId));
    }
  };

  const copyShareableLink = (shareableLink) => {
    const url = `${window.location.origin}/read-only/${shareableLink}`;
    navigator.clipboard.writeText(url);
    toast.success('Shareable link copied to clipboard!');
  };

  const handleEditProjectName = (project) => {
    setEditableProjectId(project.id);
    setNewProjectName(project.name);
  };

  const handleCancelEdit = () => {
    setEditableProjectId(null);
    setNewProjectName('');
  };

  const handleSaveEdit = (projectId) => {
    renameProject(projectId, newProjectName);
  };

  return (
    <Box className="your-projects">
      <Box className="projects-container">
        {projects.map((project) => (
          <Box key={project.id} className="project-card">
            <Box className="project-card-header">
              {editableProjectId === project.id ? (
                <TextField
                  value={newProjectName}
                  onChange={(e) => setNewProjectName(e.target.value)}
                  className="project-name-input"
                  variant="outlined"
                  size="small"
                  autoFocus
                />
              ) : (
                <Typography variant="h6" className="project-name">
                  {project.name}
                </Typography>
              )}
              <Typography className="project-date">
                {new Date(project.created_at).toLocaleString()}
              </Typography>
            </Box>
            <Box className="project-card-actions">
              <Tooltip title="Load project">
                <Button variant="contained" className="primary" onClick={() => handleLoadProject(project.id)}>
                  Load Project
                </Button>
              </Tooltip>
              {editableProjectId === project.id ? (
                <>
                  <Tooltip title="Save name">
                    <IconButton className="secondary icon-button-small" onClick={() => handleSaveEdit(project.id)}>
                      <CheckIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Cancel">
                    <IconButton className="secondary icon-button-small" onClick={handleCancelEdit}>
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <Tooltip title="Edit project name">
                  <IconButton className="secondary icon-button-small" onClick={() => handleEditProjectName(project)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Copy shareable link">
                <IconButton className="secondary icon-button-small" onClick={() => copyShareableLink(project.shareable_link)}>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete project">
                <IconButton className="secondary icon-button-small" onClick={() => deleteProject(project.id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default LoadProject;
