// utils.js
export function stripHtmlTags(html) {
  const div = document.createElement('div');
  div.innerHTML = html;

  // Remove all <style> elements
  const styleElements = div.getElementsByTagName('style');
  for (let i = styleElements.length - 1; i >= 0; i--) {
    styleElements[i].parentNode.removeChild(styleElements[i]);
  }

  // Remove all inline styles
  const elementsWithStyle = div.querySelectorAll('[style]');
  elementsWithStyle.forEach(element => {
    element.removeAttribute('style');
  });

  return div.textContent || div.innerText || '';
}
