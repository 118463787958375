import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { supabase } from '../supabaseClient';
import useStore from '../zustandStore';
import { toast } from 'react-toastify';
import { Box, TextField, Button, Checkbox, FormControlLabel, CircularProgress } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import './YourWork.css';

const SaveProject = ({ user, currentProjectId, setCurrentProjectId, onProjectSaved, handleProjectNameChange }) => {
  const [projectName, setProjectName] = useState('');
  const [loading, setLoading] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const nodes = useStore((state) => state.nodes);
  const edges = useStore((state) => state.edges);
  const results = useSelector((state) => state.results);
  const userInput = useSelector((state) => state.userInput);

  useEffect(() => {
    console.log("User ID:", user.id);
  }, [user]);

  const saveOrUpdateProject = async () => {
    if (!user) {
      toast.error('User not logged in');
      return;
    }

    if (!projectName) {
      toast.error('Project name cannot be empty');
      return;
    }

    const projectData = { nodes, edges, results, userInput, public: isPublic };
    setLoading(true);

    try {
      const { data: existingUsers, error: fetchUserError } = await supabase
        .from('users')
        .select('id')
        .eq('id', user.id);

      if (fetchUserError) {
        console.error("Fetch User Error:", fetchUserError);
        throw fetchUserError;
      }

      if (!existingUsers || existingUsers.length === 0) {
        throw new Error('User not found in users table');
      }

      const { data: existingProjects, error: fetchError } = await supabase
        .from('projects')
        .select('id')
        .eq('name', projectName)
        .eq('user_id', user.id);

      if (fetchError) {
        console.error("Fetch Error:", fetchError);
        throw fetchError;
      }

      console.log("Existing Projects:", existingProjects);

      if (existingProjects.length > 0 && !currentProjectId) {
        toast.error('Project name must be unique');
        setLoading(false);
        return;
      }

      if (currentProjectId) {
        const { error } = await supabase
          .from('projects')
          .update({ name: projectName, data: projectData, public: isPublic })
          .eq('id', currentProjectId);

        if (error) {
          console.error("Update Error:", error);
          throw error;
        }
        toast.success('Project updated successfully!');
        onProjectSaved({ id: currentProjectId, name: projectName, data: projectData });
      } else {
        const shareableLink = uuidv4(); 
        const { data, error } = await supabase
          .from('projects')
          .insert([
            { user_id: user.id, name: projectName, data: projectData, public: isPublic, shareable_link: shareableLink }
          ])
          .select(); 

        if (error) {
          console.error("Insert Error:", error);
          throw error;
        }

        if (!data || data.length === 0) {
          console.error('Insert failed, no data returned', { data, error });
          throw new Error('Insert failed, no data returned');
        }

        setCurrentProjectId(data[0].id);
        toast.success('Project saved successfully!');
        onProjectSaved({ id: data[0].id, name: projectName, data: projectData });
      }
      handleProjectNameChange(projectName);  // Update the project name here
    } catch (error) {
      if (error.message === 'User not found in users table') {
        toast.error('User not found in users table');
      } else if (error.code === '23505') {
        toast.error('Project name must be unique');
      } else {
        toast.error('Error saving project');
      }
      console.error("Error saving project:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className="save-project">
      <TextField
        label="Project Name"
        value={projectName}
        onChange={(e) => setProjectName(e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
        disabled={loading}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={isPublic}
            onChange={(e) => setIsPublic(e.target.checked)}
            color="primary"
            disabled={loading}
          />
        }
        label="Make project public"
      />
      <Button variant="contained" color="primary" onClick={saveOrUpdateProject} className="save-project-button" disabled={loading}>
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Save Project'}
      </Button>
    </Box>
  );
};

export default SaveProject;
