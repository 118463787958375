import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Handle } from 'react-flow-renderer';
import { Box, TextField, Typography, IconButton, Paper, Button } from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon, Save as SaveIcon } from '@mui/icons-material';
import { setUserInput } from '../slices/userInputSlice';
import './UserInputNode.css';

const UserInputNode = ({ id, data }) => {
  const dispatch = useDispatch();
  const userInput = useSelector((state) => state.userInput[id] || '');
  const [input, setInput] = useState(userInput);
  const textAreaRef = useRef(null);

  useEffect(() => {
    setInput(userInput);
    console.log(`UserInputNode: useEffect triggered for node ${id} with userInput: ${userInput}`);
  }, [userInput, id]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
    console.log(`UserInputNode: handleInputChange triggered for node ${id} with value: ${e.target.value}`);
  };

  const handleBlur = () => {
    console.log(`UserInputNode: handleBlur triggered for node ${id} with input: ${input}`);
    dispatch(setUserInput({ id, value: input }));
  };

  const handleSaveClick = () => {
    console.log(`UserInputNode: handleSaveClick triggered for node ${id} with input: ${input}`);
    dispatch(setUserInput({ id, value: input }));
  };

  const handleAddNodeClick = () => {
    console.log(`UserInputNode: Adding node from ${id}`);
    data.toggleDropdown(id);
  };

  const handleDeleteClick = () => {
    console.log(`UserInputNode: Deleting node ${id}`);
    data.onDelete(id);
  };

  useEffect(() => {
    const textArea = textAreaRef.current;

    if (textArea) {
      const stopScrollPropagation = (e) => {
        e.stopPropagation();
      };

      textArea.addEventListener('wheel', stopScrollPropagation);

      return () => {
        textArea.removeEventListener('wheel', stopScrollPropagation);
      };
    }
  }, []);

  return (
    <Paper className="user-input-node" elevation={3}>
      <Handle
        type="target"
        position="left"
        style={{ background: '#6366f1', width: '12px', height: '12px', borderRadius: '50%' }}
      />
      <Box className="user-input-node-header">
        <i className={`${data.icon} node-header-icon`} />
        <Typography variant="h6" className="node-title">
          What do you want to solve?
        </Typography>
      </Box>
      <Box className="user-input-node-content">
        <Typography variant="body2" className="node-description">
          Please provide a detailed description of the issue you are encountering and then drag a new item from menu onto the canvas. This will set your starting point in the canvas to understand and address the issue effectively. Then click on + icon.
        </Typography>
        <TextField
          value={input}
          onChange={handleInputChange}
          onBlur={handleBlur}
          placeholder="Enter your problem here"
          multiline
          rows={6}
          variant="outlined"
          fullWidth
          className="input-field"
          inputRef={textAreaRef}
        />
      </Box>
      <Box className="button-container" sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <IconButton onClick={handleDeleteClick} className="icon-button">
          <DeleteIcon />
        </IconButton>
        <Button
          onClick={handleSaveClick}
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          className="save-button"
        >
          Save
        </Button>
        <Button
          onClick={handleAddNodeClick}
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          className="add-button"
          sx={{
            backgroundColor: '#00e37f',
            '&:hover': {
              backgroundColor: '#00c26b',
            },
          }}
        >
          Add Node To Canvas
        </Button>
      </Box>
      <Handle
        type="source"
        position="right"
        style={{ background: '#6366f1', width: '12px', height: '12px', borderRadius: '50%' }}
      />
      <IconButton
        className="node-add-button"
        onClick={handleAddNodeClick}
        color="primary"
        style={{
          position: 'absolute',
          top: '-15px',
          right: '-15px',
          background: '#00e37f',
          color: 'white',
          borderRadius: '50%',
          width: '40px',
          height: '40px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <AddIcon />
      </IconButton>
    </Paper>
  );
};

export default UserInputNode;