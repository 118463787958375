import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { Box, Typography, Button } from '@mui/material';
import FlowCanvas from './FlowCanvas'; // Use your existing FlowCanvas component
import QuillEditor from './QuillEditor'; // Make sure to import your editor component
import './ReadOnlyProject.css';

const ReadOnlyProject = () => {
  const { shareableLink } = useParams();
  const [project, setProject] = useState(null);
  const [showEditor, setShowEditor] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const editorRef = useRef(null);
  const navigate = useNavigate(); // Import the useNavigate hook

  useEffect(() => {
    const fetchProject = async () => {
      const { data, error } = await supabase
        .from('projects')
        .select('*')
        .eq('shareable_link', shareableLink)
        .single();

      if (error) {
        console.error('Error fetching project:', error);
      } else {
        setProject(data);
      }
    };

    fetchProject();
  }, [shareableLink]);

  if (!project) {
    return <p>Loading project...</p>;
  }

  const { name, data } = project;
  const { nodes, edges, results, userInput } = data;

  const handleViewResult = (nodeId) => {
    setShowEditor(true);
    setActiveTab(nodeId);
    if (editorRef.current) {
      editorRef.current.setActiveTab(nodeId);
    }
  };

  const handleMinimizeEditor = () => {
    setShowEditor(false);
  };

  const handleSignupClick = () => {
    navigate('/login'); // Redirect to Login.js
  };

  return (
    <Box className="read-only-project">
      <Box className="signup-button-container">
        <Button variant="contained" color="primary" onClick={handleSignupClick}>
          Signup
        </Button>
      </Box>
      <Typography variant="h4">{name}</Typography>
      <FlowCanvas 
        user={null} 
        initialNodes={nodes} 
        initialEdges={edges} 
        initialResults={results} 
        initialUserInput={userInput}
        readOnly={true} // Pass a readOnly prop to make the canvas non-editable
        onViewResult={handleViewResult} // Pass the handleViewResult function
        editorRef={editorRef} // Pass the editorRef
      />
      {showEditor && (
        <div className="editor-pane show">
          <QuillEditor ref={editorRef} onMinimize={handleMinimizeEditor} activeTab={activeTab} />
        </div>
      )}
    </Box>
  );
};

export default ReadOnlyProject;
