import { createSlice } from '@reduxjs/toolkit';

const nodesSlice = createSlice({
  name: 'nodes',
  initialState: [],
  reducers: {
    setNodes: (state, action) => {
      return action.payload;
    },
    addNode: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.push(...action.payload);
      } else {
        state.push(action.payload);
      }
    },
    removeNode: (state, action) => {
      return state.filter((node) => node.id !== action.payload);
    },
    updateNodeData: (state, action) => {
      const { id, data } = action.payload;
      const nodeIndex = state.findIndex((node) => node.id === id);
      if (nodeIndex !== -1) {
        state[nodeIndex] = { ...state[nodeIndex], data: { ...state[nodeIndex].data, ...data } };
      }
    },
  },
});

export const { setNodes, addNode, removeNode, updateNodeData } = nodesSlice.actions;
export default nodesSlice.reducer;