import { configureStore } from '@reduxjs/toolkit';
import nodesReducer from './slices/nodesSlice';
import edgesReducer from './slices/edgesSlice';
import resultsReducer from './slices/resultsSlice';
import userInputReducer from './slices/userInputSlice';

const store = configureStore({
  reducer: {
    nodes: nodesReducer,
    edges: edgesReducer,
    results: resultsReducer,
    userInput: userInputReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export default store;