import React from 'react';
import { useDrag } from 'react-dnd';
import { Paper, Box, Typography, Tooltip } from '@mui/material';
import { motion } from 'framer-motion';

const DraggableItem = ({ name, icon, color, type, item, description }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type,
    item: { ...item, type },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const handleClick = () => {
    const event = new CustomEvent('moduleClick', {
      detail: { 
        item: { ...item, type },
        position: {
          x: window.innerWidth / 2 - 200,
          y: window.innerHeight / 2 - 100
        }
      }
    });
    document.dispatchEvent(event);
  };

  const isScenarioOrUserInput = type === 'SCENARIO' || type === 'USER_INPUT';
  const MotionPaper = motion(Paper);

  // Wrap content in Tooltip only for scenario and user input types
  const Content = (
    <MotionPaper
      ref={drag}
      onClick={handleClick}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      sx={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'grab',
        width: isScenarioOrUserInput ? '100%' : '220px',
        height: isScenarioOrUserInput ? '60px' : '160px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
        transition: 'all 0.3s ease',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '12px',
        overflow: 'hidden',
        border: '1px solid rgba(0, 0, 0, 0.06)',
        '&:hover': {
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.12)',
          borderColor: 'rgba(0, 0, 0, 0.12)',
        },
        backgroundColor: isScenarioOrUserInput ? color || '#fffae6' : '#ffffff',
      }}
    >
      <Box
        sx={{
          backgroundColor: isScenarioOrUserInput ? 'transparent' : (color || 'var(--card-background)'),
          padding: isScenarioOrUserInput ? '12px 20px' : '16px 12px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: isScenarioOrUserInput ? 'flex-start' : 'center',
          height: isScenarioOrUserInput ? '100%' : 'auto',
          color: 'var(--text-color)',
          gap: '12px',
        }}
      >
        {icon && (
          <Box
            component="span"
            className={icon}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '24px',
              color: 'rgba(0, 0, 0, 0.7)',
            }}
          />
        )}
        <Typography
          className="module-label"
          sx={{
            fontWeight: '600',
            fontSize: '0.9rem',
            textAlign: isScenarioOrUserInput ? 'left' : 'center',
            flexGrow: 1,
            lineHeight: 1.4,
            color: 'rgba(0, 0, 0, 0.8)',
          }}
        >
          {name}
        </Typography>
      </Box>

      {!isScenarioOrUserInput && (
        <Box
          sx={{
            padding: '16px',
            textAlign: 'center',
            flexGrow: 1,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderTop: '1px solid rgba(0, 0, 0, 0.04)',
          }}
        >
          {description && (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '0.85rem',
                lineHeight: 1.5,
                color: 'rgba(0, 0, 0, 0.6)',
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}
            >
              {description}
            </Typography>
          )}
        </Box>
      )}
    </MotionPaper>
  );

  // Return tooltip wrapped content only for scenario and user input types
  return isScenarioOrUserInput ? (
    <Tooltip title={description} placement="right" arrow>
      {Content}
    </Tooltip>
  ) : Content;
};

export default DraggableItem;