import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { MemberstackProvider } from '@memberstack/react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import App from './App';
import store from './store';
import './index.css';

const theme = createTheme({
  palette: {
    mode: 'light', // Ensure this is set to 'light' or 'dark'
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#e2e2e2',
    },
    background: {
      default: '#ffffff',
      paper: '#f5f5f5',
    },
    text: {
      primary: '#000000',
      secondary: '#666666',
    },
  },
});

const config = { publicKey: 'pk_79552f0c2b88f62443c6' };

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <MemberstackProvider config={config}>
    <ReduxProvider store={store}>
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </DndProvider>
    </ReduxProvider>
  </MemberstackProvider>
);
