import React, { useState, useEffect } from 'react';
import { Box, TextField, Button } from '@mui/material';
import { supabase } from '../supabaseClient';
import { toast } from 'react-toastify';

const EditAccount = ({ user }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setName(user.name || '');  // Using user.name instead of user_metadata.full_name
      setEmail(user.email);
    }
  }, [user]);

  const handleUpdate = async () => {
    setLoading(true);
    const { error } = await supabase
      .from('users')
      .update({ name, email })
      .eq('id', user.id);

    setLoading(false);

    if (error) {
      toast.error('Error updating account');
    } else {
      toast.success('Account updated successfully');
    }
  };

  if (!user) {
    return null; // Return null if user is not available
  }

  return (
    <Box>
      <TextField
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpdate}
        disabled={loading}
      >
        {loading ? 'Updating...' : 'Update Account'}
      </Button>
    </Box>
  );
};

export default EditAccount;
