import { createSlice } from '@reduxjs/toolkit';

const userInputSlice = createSlice({
  name: 'userInput',
  initialState: {},
  reducers: {
    setUserInput: (state, action) => {
      if (action.payload && action.payload.id !== undefined && action.payload.value !== undefined) {
        const { id, value } = action.payload;
        console.log('setUserInput:', { id, value }); // Debugging
        state[id] = value;
      } else {
        console.error('Invalid payload for setUserInput:', action.payload);
      }
    },
  },
});

export const { setUserInput } = userInputSlice.actions;
export default userInputSlice.reducer;
