import { supabase } from './supabaseClient';

export async function fetchPrompt(category, moduleId) {
  console.log(`fetchPrompt: Fetching prompt for category: ${category}, moduleId: ${moduleId}`);
  const { data, error } = await supabase
    .from('prompts')
    .select('*')
    .eq('category', category)
    .eq('moduleid', moduleId)
    .single();

  if (error) {
    throw error;
  }
  return data;
}

export async function executePrompt(nodes, userInput, userId) {
  console.log(`executePrompt: Executing prompt with nodes and userInput:`, nodes, userInput, userId);

  const input = userInput || 'Please provide more information about this topic.';

  const response = await fetch(`${process.env.REACT_APP_API_URL}/execute`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ nodes, userInput: input, userId }),
  });

  if (!response.ok) {
    const error = await response.text();
    throw new Error(`Error executing prompt: ${error}`);
  }

  const data = await response.json();
  console.log('executePrompt: Full API response:', JSON.stringify(data, null, 2));
  return data;
}
