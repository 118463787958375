import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, TextField, Select, MenuItem, CircularProgress, IconButton, Typography, Paper, Divider, ListItemIcon, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SaveIcon from '@mui/icons-material/Save';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Draggable from 'react-draggable';
import axios from 'axios';
import './PopupContent.css';

const PopupContent = ({ selectedText, promptOptions, onSave, onClose }) => {
    const [selectedPrompt, setSelectedPrompt] = useState('');
    const [response, setResponse] = useState('');
    const [loading, setLoading] = useState(false);
    const [editableText, setEditableText] = useState(selectedText);
    const popupRef = useRef(null);
    const clickInsidePopup = useRef(false);

    const handlePromptChange = (event) => {
        setSelectedPrompt(event.target.value);
    };

    const handleGenerate = async () => {
        setLoading(true);
        try {
            const result = await axios.post('/api/gpt-prompt', {
                selectedText: editableText,
                promptId: selectedPrompt,
            });
            setResponse(result.data.gptResponse || '');
        } catch (error) {
            console.error('Error generating response:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleMouseDown = () => {
        clickInsidePopup.current = true;
    };

    const handleMouseUp = () => {
        clickInsidePopup.current = false;
    };

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target) && !clickInsidePopup.current) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Draggable>
            <div>
                <Paper ref={popupRef} className="popup-container" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} elevation={3}>
                    <Box className="popup-header">
                        <Typography variant="h6" className="popup-title">
                            <WhatshotIcon /> Quick Fire
                        </Typography>
                        <IconButton size="small" className="popup-close-button" onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider className="popup-divider" />
                    <Box className="popup-content">
                        <Select
                            value={selectedPrompt || ''}
                            onChange={handlePromptChange}
                            displayEmpty
                            fullWidth
                            variant="outlined"
                            className="popup-select"
                            renderValue={(selected) => {
                                if (!selected) {
                                    return <span>Select Action</span>;
                                }
                                const selectedPrompt = promptOptions.find(option => option.id === selected);
                                return (
                                    <Box display="flex" alignItems="center">
                                        <MenuBookIcon style={{ marginRight: 8 }} />
                                        {selectedPrompt.prompt_name}
                                    </Box>
                                );
                            }}
                        >
                            <MenuItem value="" disabled>
                                <ListItemIcon>
                                    <MenuBookIcon />
                                </ListItemIcon>
                                <ListItemText primary="Select a prompt" />
                            </MenuItem>
                            {promptOptions.map((prompt) => (
                                <MenuItem key={prompt.id} value={prompt.id}>
                                    <ListItemIcon>
                                        <MenuBookIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={prompt.prompt_name} />
                                </MenuItem>
                            ))}
                        </Select>
                        <TextField
                            value={editableText || ''}
                            onChange={(e) => setEditableText(e.target.value)}
                            fullWidth
                            multiline
                            variant="outlined"
                            className="popup-textfield"
                            label="Selected Text"
                        />
                        <div className="spacer"></div> {/* Spacer to increase gap */}
                        {loading ? <CircularProgress className="popup-progress" /> : (
                            <TextField
                                value={response || ''}
                                onChange={(e) => setResponse(e.target.value)}
                                fullWidth
                                multiline
                                variant="outlined"
                                placeholder="Response will appear here..."
                                className="popup-response-field"
                            />
                        )}
                    </Box>
                    <Box className="popup-buttons">
                        <Button
                            onClick={handleGenerate}
                            className="popup-button"
                            variant="contained"
                            disabled={loading || !selectedPrompt}
                            startIcon={<PlayArrowIcon />}
                        >
                            Explore
                        </Button>
                       
                    </Box>
                </Paper>
            </div>
        </Draggable>
    );
};

export default PopupContent;
